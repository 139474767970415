import React, {Component} from 'react';
import {WhiteBox} from "amn/ui/containers/Boxes";
import Box from "@material-ui/core/Box";
import type {SecurityStandardGuideline} from "amn/model/standards/SecurityStandards";
import {securityStandardGuidelineReference} from "amn/model/standards/SecurityStandards";
import ReactMarkdown from "react-markdown";
import GuidelineUserNotes from "./actions/GuidelineUserNotes";
import I18n from "amn/ui/i18n/I18n";
import {I18N_NS_STANDARDS} from "amn/common/i18n/I18nextConfig";
import Tooltip from "@material-ui/core/Tooltip";
import ButtonBase from "@material-ui/core/ButtonBase";
import {Link} from "react-router-dom";
import OpenInNewOutlinedIcon from "@material-ui/icons/OpenInNewOutlined";
import {SecurityStandardContext} from "../SecurityStandardContexts";
import {buildGuidelineUrl} from "amn/modules/common/routes/standards-routes";

type Props = {
  guideline: SecurityStandardGuideline
};

type State = {
  selectedTab: number;
};

class GuidelineDetails extends Component<Props, State> {

  static contextType = SecurityStandardContext;

  state = {
    selectedTab: 0
  };

  onSelectedTabChange = (event, selectedTab) => {
    this.setState({selectedTab});
  };

  render() {
    return (
      <React.Fragment>
        <WhiteBox p={1}>
          {this.renderDocumentation()}
        </WhiteBox>
      </React.Fragment>
    );
  }

  renderDocumentation = () => {
    const {guideline} = this.props;
    const {securityStandard} = this.context;
    return (
      <React.Fragment>
        <Box p={2} display={"flex"} justifyContent={"flex-end"}>
          <Box>

            <Tooltip title={
              <I18n ns={I18N_NS_STANDARDS} i18nKey="standardGuidelines.open">
                Open
              </I18n>
            }>
              <ButtonBase component={Link}
                          to={buildGuidelineUrl(securityStandard?.id, guideline?.id)}>
                <OpenInNewOutlinedIcon color="secondary"/>
              </ButtonBase>
            </Tooltip>
          </Box>
        </Box>
        <Box p={2}>
          <GuidelineUserNotes guideline={guideline}/>
        </Box>
        <Box p={2}>

          <Box fontWeight="fontWeightMedium"
               mt={1}
               fontSize="body2.fontSize"
               color={"secondary.main"}>
            {securityStandardGuidelineReference(guideline)} - {guideline?.title}
          </Box>


          {guideline?.description &&
          <React.Fragment>
            <Box mt={2} fontSize="caption.fontSize">
              <ReactMarkdown children={guideline?.description.replaceAll("\n", "  \n")}/>
            </Box>
          </React.Fragment>
          }
          {!guideline?.description &&
          <Box mt={2} mb={1} color={"text.secondary"}>
            <I18n ns={I18N_NS_STANDARDS} i18nKey="standardGuidelines.fields.description.noDocumentation">
              No description available
            </I18n>
          </Box>

          }
        </Box>
      </React.Fragment>
    );
  }


}


export default GuidelineDetails;




