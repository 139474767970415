import {createSelector} from 'reselect';
import get from "lodash/get";

export const selectIAMUI = (state) => get(state, 'iam.ui');

export const isLoginDialogOpen = createSelector(
  selectIAMUI,
  (state) => get(state, 'loginDialog.open')
);

export const selectLoginReason = createSelector(
  selectIAMUI,
  (state) => get(state, 'loginDialog.reason')
);

