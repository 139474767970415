import store from "amn/store";
import type {ActionPromise} from "../common";
import type {SecurityStandardUserNote} from "amn/model/standards/SecurityStandards";
import type {CreationResponse, ListResponse, UpdateResponse} from "amn/model/common/Responses";
import SecurityStandardsUserNotesBackendAdapter from "amn/backend/adapters/SecurityStandardsUserNotesBackendAdapter";


export const LOAD_STANDARD_USER_NOTE_REQUEST = 'app/standards/LOAD_STANDARD_USER_NOTE_REQUEST';
export const LOAD_STANDARD_USER_NOTE_SUCCESS = 'app/standards/LOAD_STANDARD_USER_NOTE_SUCCESS';
export const LOAD_STANDARD_USER_NOTE_ERROR = 'app/standards/LOAD_STANDARD_USER_NOTE_ERROR';


export function loadStandardUserNotes(userId: string): ActionPromise<{ userId: string }, ListResponse<SecurityStandardUserNote>> {
  return store.dispatch({
    types: [LOAD_STANDARD_USER_NOTE_REQUEST, LOAD_STANDARD_USER_NOTE_SUCCESS, LOAD_STANDARD_USER_NOTE_ERROR],
    userId,
    promise: SecurityStandardsUserNotesBackendAdapter.loadUserNotes(userId)
  });
}


export const CREATE_STANDARD_USER_NOTE_REQUEST = 'app/standards/CREATE_STANDARD_USER_NOTE_REQUEST';
export const CREATE_STANDARD_USER_NOTE_SUCCESS = 'app/standards/CREATE_STANDARD_USER_NOTE_SUCCESS';
export const CREATE_STANDARD_USER_NOTE_ERROR = 'app/standards/CREATE_STANDARD_USER_NOTE_ERROR';


export function createStandardUserNote(userNote: Partial<SecurityStandardUserNote>): ActionPromise<{ userNote: Partial<SecurityStandardUserNote> }, CreationResponse> {
  return store.dispatch({
    types: [CREATE_STANDARD_USER_NOTE_REQUEST, CREATE_STANDARD_USER_NOTE_SUCCESS, CREATE_STANDARD_USER_NOTE_ERROR],
    entity: userNote,
    promise: SecurityStandardsUserNotesBackendAdapter.createUserNote(userNote)
  });
}

export const UPDATE_STANDARD_USER_NOTE_REQUEST = 'app/standards/UPDATE_STANDARD_USER_NOTE_REQUEST';
export const UPDATE_STANDARD_USER_NOTE_SUCCESS = 'app/standards/UPDATE_STANDARD_USER_NOTE_SUCCESS';
export const UPDATE_STANDARD_USER_NOTE_ERROR = 'app/standards/UPDATE_STANDARD_USER_NOTE_ERROR';


export function updateStandardUserNote(userNote: SecurityStandardUserNote): ActionPromise<{ userNote: Partial<SecurityStandardUserNote> }, UpdateResponse> {
  return store.dispatch({
    types: [UPDATE_STANDARD_USER_NOTE_REQUEST, UPDATE_STANDARD_USER_NOTE_SUCCESS, UPDATE_STANDARD_USER_NOTE_ERROR],
    entity: userNote,
    promise: SecurityStandardsUserNotesBackendAdapter.updateUserNote(userNote)
  });
}


export const DELETE_STANDARD_USER_NOTE_REQUEST = 'app/standards/DELETE_STANDARD_USER_NOTE_REQUEST';
export const DELETE_STANDARD_USER_NOTE_SUCCESS = 'app/standards/DELETE_STANDARD_USER_NOTE_SUCCESS';
export const DELETE_STANDARD_USER_NOTE_ERROR = 'app/standards/DELETE_STANDARD_USER_NOTE_ERROR';


export function deleteStandardUserNote(id: string): ActionPromise<{ id: string }, void> {
  return store.dispatch({
    types: [DELETE_STANDARD_USER_NOTE_REQUEST, DELETE_STANDARD_USER_NOTE_SUCCESS, DELETE_STANDARD_USER_NOTE_ERROR],
    id,
    promise: SecurityStandardsUserNotesBackendAdapter.deleteUserNote(id)
  });
}
