import React, {Component} from 'react';
import SecurityStandardGuidelineDetailsViewer from "../components/guideline/SecurityStandardGuidelineDetailsViewer";


class SecurityStandardGuidelineDetailsScreen extends Component {


  render() {
    const {match} = this.props;
    const {params} = match;
    const securityStandardId = params.standardId;
    const guidelineId = params.id;
    return (
      <SecurityStandardGuidelineDetailsViewer securityStandardId={securityStandardId}
                                              key={guidelineId}
                                              guidelineId={guidelineId}/>
    );
  }

}


export default SecurityStandardGuidelineDetailsScreen;

