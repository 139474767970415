import React, {useState} from 'react';
import Box from "@material-ui/core/Box";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";
import {WhiteBox} from "./Boxes";

type Props = {
  title: React.ReactNode,
  initiallyOpen?: boolean,
  children?: React.ReactNode,
};


const CollapsiblePanel = ({title, initiallyOpen = true, children}: Props) => {
  const [open, setOpen] = useState(initiallyOpen);
  return (
    <WhiteBox>
      <Box p={2}
           alignItems="center"
           display="flex">
        <Box flexGrow={1}>
          {title}
        </Box>
        <Box justifyContent="center"
             display="flex">
          <Tooltip title="Expand/Collapse">
            <IconButton onClick={() => setOpen(!open)}>
              {open ? <ExpandLess/> : <ExpandMore/>}
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </WhiteBox>
  );
}


export default CollapsiblePanel;



