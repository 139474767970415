import React, {Component} from 'react';
import type {RichText} from "./RichText";
import MUIRichTextEditor from "mui-rte";
import {withStyles} from "@material-ui/core/styles";

type Props = {
  value: RichText
};

const FlatRichTextEditor = withStyles((theme) => ({
  container: {
    border: "none",
    fontSize: "inherit"
  },
  editor: {
    padding: "0px",
  },
  // toolbar: {
  //   borderBottom: "1px solid " + "#f1f3f7",
  //   background: "#f9f9f9"
  // },
  placeHolder: {
    position: "initial",
    padding: "6px 8px 0 8px",
  }
}))(MUIRichTextEditor);

class RichTextValue extends Component<Props> {

  constructor(props) {
    super(props);
  }

  render() {
    const {value} = this.props;

    return (
      <FlatRichTextEditor {...this.props}
                         readOnly
                         toolbar={false}
                         defaultValue={value?.content}/>
    );
  }
}


export default RichTextValue;



