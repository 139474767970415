import React from "react";
import Button, {ButtonProps} from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import I18n from "../i18n/I18n";
import IconButton from "@material-ui/core/IconButton";


type Props = {
  onConfirm: () => void;
  confirmationMessage: string;
  dialogTitle?: string;
  cancelLabel?: string;
  confirmLabel?: string;
  ButtonComponent?: React.ReactNode
} & ButtonProps;

const ConfirmButton = ({
                         onConfirm,
                         dialogTitle = (<I18n>Confirmation</I18n>),
                         confirmationMessage,
                         confirmLabel = (<I18n>Yes</I18n>),
                         cancelLabel = (<I18n>No</I18n>),
                         ButtonComponent = IconButton,
                         ...props
                       }: Props) => {

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (event) => {
    setOpen(true);
    props.onClick && props.onClick(event);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const confirm = () => {
    setOpen(false);
    onConfirm && onConfirm();
  };

  return (
    <React.Fragment>
      <ButtonComponent {...props} onClick={handleClickOpen}/>
      <Dialog
        open={open}
        onClose={handleClose}>
        {dialogTitle &&
        <DialogTitle>
          {dialogTitle}
        </DialogTitle>
        }
        <DialogContent>
          <DialogContentText>
            {confirmationMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            {cancelLabel}
          </Button>
          <Button onClick={confirm} color="primary">
            {confirmLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}

export default ConfirmButton;
