import React, {Component} from 'react';
import Box from "@material-ui/core/Box";
import FlexSearch from "flexsearch";
import trim from "lodash/trim";
import intersection from "lodash/intersection";
import includes from "lodash/includes";
import forEach from "lodash/forEach";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import SecurityStandardGuidelinesTree from "../tree/SecurityStandardGuidelinesTree";
import {WhiteBox} from "amn/ui/containers/Boxes";
import type {
  SecurityStandard,
  SecurityStandardGuideline,
  SecurityStandardGuidelineRelationships,
  SecurityStandardVariant
} from "amn/model/standards/SecurityStandards";
import {SecurityStandardContext} from "../tree/SecurityStandardContexts";
import size from "lodash/size";
import reduce from "lodash/reduce";
import {
  selectSecurityStandardGuidelines,
  selectSecurityStandardGuidelinesRelationships,
  selectSecurityStandardVariants
} from "amn/store/selectors/standards/security-standards.selectors";
import {connect} from "react-redux";
import Divider from "@material-ui/core/Divider";
import SecurityStandardVariantsSwitch from "../common/SecurityStandardVariantsSwitch";
import type {LookupTable} from "amn/model/common/Divers";
import {getEntityId} from "amn/model/common/Entity";
import SecurityStandardDetailsSearchControl from "../common/SecurityStandardDetailsSearchControl";
import {I18N_NS_STANDARDS} from "amn/common/i18n/I18nextConfig";
import I18n from "amn/ui/i18n/I18n";
import WithBreakpoints from "amn/ui/containers/WithBreakpoints";

type Props = {
  securityStandard: SecurityStandard;
  variants: LookupTable<SecurityStandardVariant>;
  guidelines: LookupTable<SecurityStandardGuideline>;
  guidelinesRelationships: LookupTable<SecurityStandardGuidelineRelationships>;
};

type State = {
  selectedVariantsIds: string[];
  guidelinesViewState: {
    [key: string]: {
      open: boolean,
      visible: boolean,
      highlighted: boolean,
    }
  },
  searchText: string;
};

const INDEX_CONFIG = {
  cache: false,
  doc: {
    id: "id",
    field: {
      "reference:reference": {
        split: "\\s+"
      },
      title: {
        encode: "advanced",
      },
      description: {
        encode: "advanced",
      }
    },
    store: ["id", "reference", "relatedVariants", "title"]
  }
};

class SecurityStandardDetails extends Component<Props, State> {

  constructor(props) {
    super(props);
    this.index = new FlexSearch({...INDEX_CONFIG});
    this.state = {
      selectedVariantsIds: [],
      searchText: "",
      guidelinesViewState: this.prepareViewState(props)
    }
  }

  prepareViewState(props) {
    return reduce(props.guidelines, (res, guideline) => {
      this.index.add(guideline);
      res[guideline.id] = {
        open: false,
        visible: true,
        highlighted: false
      };
      return res;
    }, {});
  }

  getGuidelineViewState = (guidelineId: string) => {
    return this.state.guidelinesViewState[guidelineId] || {visible: true, open: false}
  }

  onGuidelineOpenStateChange = (guidelineId: string, open: boolean) => {
    const guidelinesViewState = this.state.guidelinesViewState;
    this.setState({
      guidelinesViewState: {
        ...guidelinesViewState,
        [guidelineId]: {
          ...(guidelinesViewState[guidelineId] || {visible: true}),
          open
        }
      }
    });
  }

  onVariantChange = (selectedVariantsIds: string[]) => {
    this.setState({selectedVariantsIds}, () => {
      this.search(this.state.searchText, selectedVariantsIds);
    })
  }

  searchByOption = (guideline: SecurityStandardGuideline) => {
    const id = guideline?.id;
    id && this.updateGuidelinesViewStateBasedOnSearchResult([id]);
  }

  onSearchTextChange = (searchText) => {
    // const searchText = event.target.value;
    this.setState({searchText}, async () => {
      await this.search(searchText, this.state.selectedVariantsIds);
    })
  }

  search = async (searchText, selectedVariantsIds) => {
    if (!isEmpty(trim(searchText))) {

      const result = await this.index.search(searchText, {
        where: function (guideline) {
          return !isEmpty(selectedVariantsIds) ? !isEmpty(intersection(guideline.relatedVariants, selectedVariantsIds)) : true
        }
      });
      this.updateGuidelinesViewStateBasedOnSearchResult(map(result, getEntityId));

    } else if (!isEmpty(selectedVariantsIds)) {

      const {guidelinesViewState: oldGuidelinesViewState} = this.state;
      const guidelinesViewState = {...oldGuidelinesViewState};
      forEach(this.props.guidelines, (guideline: SecurityStandardGuideline) => {
        guidelinesViewState[guideline.id].visible = !isEmpty(intersection(guideline.relatedVariants, selectedVariantsIds));
        guidelinesViewState[guideline.id].highlighted = false
      });
      this.setState({guidelinesViewState});

    } else {

      const {guidelinesViewState: oldGuidelinesViewState,} = this.state;
      const guidelinesViewState = {...oldGuidelinesViewState};
      forEach(this.props.guidelines, (guideline: SecurityStandardGuideline) => {
        guidelinesViewState[guideline.id].visible = true;
        guidelinesViewState[guideline.id].highlighted = false;
      });
      this.setState({guidelinesViewState});
    }
  }

  updateGuidelinesViewStateBasedOnSearchResult = (foundGuidelinesIds: string[]) => {
    const ids = foundGuidelinesIds;
    const idsToShow = [...ids];
    const idsToOpen = [];
    forEach(ids, id => {
      const relationships: SecurityStandardGuidelineRelationships = this.props.guidelinesRelationships[id];
      idsToShow.push(...(relationships?.ancestorsIds || []))
      idsToShow.push(...(relationships?.descendentsIds || []))

      idsToOpen.push(...(relationships?.ancestorsIds || []))
    })

    const {guidelinesViewState: oldGuidelinesViewState,} = this.state;
    const guidelinesViewState = {...oldGuidelinesViewState};
    forEach(this.props.guidelines, (guideline: SecurityStandardGuideline) => {
      guidelinesViewState[guideline.id].visible = !!includes(idsToShow, guideline.id);
      guidelinesViewState[guideline.id].highlighted = !!includes(ids, guideline.id);
      guidelinesViewState[guideline.id].open = !!includes(idsToOpen, guideline.id);
    });
    this.setState({guidelinesViewState});
  }

  suggest = async (searchText) => {
    const {selectedVariantsIds} = this.state;
    return await this.index.search(searchText, {
      where: function (guideline) {
        return !isEmpty(selectedVariantsIds) ? !isEmpty(intersection(guideline.relatedVariants, selectedVariantsIds)) : true
      }
    })
  };

  render() {

    const {securityStandard, variants, guidelines} = this.props;
    const {selectedVariantsIds} = this.state;
    return (
      <SecurityStandardContext.Provider
        value={{
          securityStandard,
          selectedVariantsIds,
          getGuidelineViewState: this.getGuidelineViewState,
          onGuidelineOpenStateChange: this.onGuidelineOpenStateChange
        }}>


        <WithBreakpoints>
          {(bps) => (
            <div style={bps.smUp ? null: {overflowX: "auto"}}>
              <WhiteBox p={4} width={bps.smUp ? "100%" : "200%"}>

                <Box mt={2}
                     mb={2}
                     display={"flex"}
                     alignItems="center">
                  <Box flex={2}>

                    <SecurityStandardDetailsSearchControl searchByText={this.onSearchTextChange}
                                                          placeholder="Filtrer les exigences du référentiel actuel par titre ou par numéro d'exigence"
                                                          searchByOption={this.searchByOption}
                                                          suggest={this.suggest}/>
                  </Box>

                  <Box flex={1}>

                  </Box>


                  {size(variants) > 1 &&
                  <Box ml={2}>
                    <Box color={"text.secondary"} mb={0.5}>
                      <I18n ns={I18N_NS_STANDARDS}
                            i18nKey={"standardGuidelines.fields.variants.filter"}>
                        Select level
                      </I18n>
                    </Box>
                    <SecurityStandardVariantsSwitch
                      onChange={this.onVariantChange}
                      value={selectedVariantsIds}
                      securityStandard={securityStandard}/>
                  </Box>
                  }

                </Box>
                <Divider/>

                <Box mt={5}>
                  <SecurityStandardGuidelinesTree
                    securityStandard={securityStandard}/>
                </Box>
              </WhiteBox>
            </div>
          )}
        </WithBreakpoints>
      </SecurityStandardContext.Provider>
    );
  }

  static mapStateToProps = (state, props) => {
    return {
      variants: selectSecurityStandardVariants(state)(props.securityStandard?.id),
      guidelines: selectSecurityStandardGuidelines(state)(props.securityStandard?.id),
      guidelinesRelationships: selectSecurityStandardGuidelinesRelationships(state)(props.securityStandard?.id),
    }
  };
}

export default connect(SecurityStandardDetails.mapStateToProps)(SecurityStandardDetails);

