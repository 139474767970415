import React, {Component} from 'react';
import {Auth0Lock} from "auth0-lock";
import Config from "amn/Config";
import {signInWithCustomToken, updateProfile} from "amn/firebase/auth/auth.actions";
import {mainColor} from "amn/modules/theme/theme";
import "./LoginForm.css";

type Props = {
  onLoginSuccess: ()=> void;
  reason?: string;
};

type State = {};

const auth0LockConfig = {
  configurationBaseUrl: 'https://cdn.eu.auth0.com',
  autofocus: true,
  avatar: null,
  container: "iam-login-form",
  language: "fr",
  mustAcceptTerms: true,
  auth: {
    redirect: false,
    redirectUrl: window.location.origin,
    responseType: 'token id_token',
    params: {
      scope: 'openid profile email'
    },
  },
  theme: {
    logo: require("assets/images/logo/amn-brains-flat.png"),
    primaryColor: mainColor
  },
};

class LoginDialog extends Component<Props, State> {


  constructor(props) {
    super(props);
    const config = this.initConfig();
    this.auth0Lock = new Auth0Lock(
      Config.AUTH0_CLIENT_ID,
      Config.AUTH0_DOMAIN,
      config,
    );
  }

  initConfig = () => {
    // TODO i18n
    return {
      ...auth0LockConfig,
      languageDictionary: {
        forgotPasswordSubmitLabel: "Envoyer",
        title: this.props.reason || "Se connecter",
        emailInputPlaceholder: "Email *",
        passwordInputPlaceholder: "Mot de passe *",
        signUpTerms: 'J’accepte <a target="_blank" href="https://amnbrains.com/privacy-policy">la politique de confidentialité</a>',
      },
      additionalSignUpFields: [
        {
          name: "family_name",
          placeholder: "Nom *",
          storage: "root",
          validator: function (name) {
            return {
              valid: name.length >= 1,
              hint: "Ne peut être vide"
            };
          }
        },
        {
          name: "given_name",
          placeholder: "Prénom *",
          storage: "root",
          validator: function (name) {
            return {
              valid: name.length >= 1,
              hint: "Ne peut être vide"
            };
          }
        },
        {
          name: "organization",
          placeholder: "Société",
          validator: function () {
            return true
          }
        },
        {
          name: "phone_number",
          placeholder: "Numéro de téléphone",
          storage: "root",
          validator: function (name) {
            return {
              valid: true,// todo validate phone number
              hint: "Numero de téléphone invalide"
            };
          }
        }
      ]
    }
  }

  componentDidMount() {

    this.auth0Lock.on("authenticated", async (authResult) => {
      await signInWithCustomToken(authResult.idToken);
      this.auth0Lock.getUserInfo(authResult.accessToken, (error, profile) => {
        if (error) {
          // todo Handle error
          console.log(error)
          return;
        }
        updateProfile(profile)
      });
      setTimeout(() => {
        this.props.onLoginSuccess();

      }, 2000)
    })
    this.auth0Lock.show()
  }

  render() {
    return (
      <div id="iam-login-form"/>
    );
  }
}


export default LoginDialog;



