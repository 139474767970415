import {openLoginDialog} from "../../actions/iam/iam-ui.actions";
import firebaseAuth from "amn/firebase/auth/FirebaseAuth";
import store from "../../index";
import {isLoginDialogOpen} from "../../selectors/iam/iam-ui.selectors";


export default {

  doIfAuthed: (func, reason) => {
    if (firebaseAuth.getCurrentUser()) {
      func();
    } else {
      openLoginDialog(reason);
      const unsubscribe = store.subscribe(() => {
        if (!isLoginDialogOpen(store.getState())) {
          if (firebaseAuth.getCurrentUser()) {
            func();
          }
          unsubscribe();
        }
      });
    }
  }
}
