import {createSelector} from 'reselect';
import get from "lodash/get";
import find from "lodash/find";
import reduce from "lodash/reduce";
import type {
  SecurityStandard,
  SecurityStandardGuideline, SecurityStandardGuidelineRelationships,
  SecurityStandardVariant
} from "amn/model/standards/SecurityStandards";
import type {LookupTable} from "amn/model/common/Divers";
import memoize from "lodash/memoize";
import type {DataStatus, ListDataState} from "../../reducers/common";
import filter from "lodash/filter";
import includes from "lodash/includes";
import {isGuidelineWithSubGuidelines} from "amn/model/standards/SecurityStandards";
import {listToLookupTable} from "amn/model/common/Divers";

export const selectSecurityStandardsState = (state) => get(state, 'securityStandards');

export const selectSecurityStandards = createSelector(
  selectSecurityStandardsState,
  (state): LookupTable<SecurityStandard> => get(state, 'entities')
);
export const selectSecurityStandardsDataStatus = createSelector(
  selectSecurityStandardsState,
  (state): LookupTable<SecurityStandard> => get(state, 'status')
);


export const makeSecurityStandardSelector = createSelector(
  selectSecurityStandards,
  (standards: LookupTable<SecurityStandard>): SecurityStandard => memoize(
    (id: string): SecurityStandard => {
      return standards[id];
    })
);



export const selectSecurityStandardGuidelinesState = createSelector(
  selectSecurityStandardsState,
  (state): ListDataState<SecurityStandardGuideline> => memoize(
    (securityStandardId) => {
      return get(state, 'subEntities.' + securityStandardId + ".guidelines")
    })
);

export const selectSecurityStandardGuidelines = createSelector(
  selectSecurityStandardGuidelinesState,
  (selectGuidelinesState): LookupTable<SecurityStandardGuideline> => memoize(
    (securityStandardId) => {
      return get(selectGuidelinesState(securityStandardId), "entities", {})
    })
);

export const selectSecurityStandardGuidelinesDataStatus = createSelector(
  selectSecurityStandardGuidelinesState,
  (selectGuidelinesState): LookupTable<SecurityStandardGuideline> => memoize(
    (securityStandardId) => {
      return get(selectGuidelinesState(securityStandardId), "status")
    })
);

export const selectAllSecurityStandardsGuidelines = createSelector(
  selectSecurityStandards,
  selectSecurityStandardGuidelines,
  (standards, getStandardGuidelines): LookupTable<SecurityStandardGuideline> =>
    reduce(standards, (result, standard) => {
      return {
        ...getStandardGuidelines(standard.id),
        ...result
      };
    }, {})
);

export const selectSecurityStandardGuidelinesRelationshipsState = createSelector(
  selectSecurityStandardsState,
  (state): ListDataState<SecurityStandardGuidelineRelationships> => memoize(
    (securityStandardId) => {
      return get(state, 'subEntities.' + securityStandardId + ".guidelinesRelationships")
    })
);

export const selectSecurityStandardGuidelinesRelationships = createSelector(
  selectSecurityStandardGuidelinesRelationshipsState,
  (selectGuidelinesState): LookupTable<SecurityStandardGuidelineRelationships> => memoize(
    (securityStandardId) => {
      return get(selectGuidelinesState(securityStandardId), "entities", {})
    })
);


export const selectSecurityStandardRootGuidelines = createSelector(
  selectSecurityStandardGuidelines,
  (selectGuidelines): LookupTable<SecurityStandardGuideline> => memoize(
    (securityStandardId) => {
      const guidelines = selectGuidelines(securityStandardId);
      const roots = filter(guidelines, guideline => {
        return !find(guidelines,
          (otherGuideline: SecurityStandardGuideline) => isGuidelineWithSubGuidelines(otherGuideline) && includes(otherGuideline.subGuidelines, guideline.id))
      });

      return listToLookupTable(roots);
    })
);


export const makeSecurityStandardGuidelineSelector = createSelector(
  selectAllSecurityStandardsGuidelines,
  (guidelines: LookupTable<SecurityStandardGuideline>) => memoize(
    (id: string): SecurityStandardGuideline => {
      return guidelines[id];
    })
);

export const selectSecurityStandardVariantsState = createSelector(
  selectSecurityStandardsState,
  (state): ListDataState<SecurityStandardVariant> => memoize(
    (securityStandardId) => {
      return get(state, 'subEntities.' + securityStandardId + ".variants")
    })
);

export const selectSecurityStandardVariants = createSelector(
  selectSecurityStandardVariantsState,
  (selectVariantsState): LookupTable<SecurityStandardVariant> => memoize(
    (securityStandardId) => {
      return get(selectVariantsState(securityStandardId), "entities")
    })
);

export const selectSecurityStandardVariantsDataStatus = createSelector(
  selectSecurityStandardVariantsState,
  (selectVariantsState): DataStatus => memoize(
    (securityStandardId) => {
      return get(selectVariantsState(securityStandardId), "status")
    })
);


