// @flow

import type {LookupTable} from "amn/model/common/Divers";

export type Reducer<T> = (T, Object) => T;


export const DATA_INITIAL = 'INITIAL';
export const DATA_LOADING = 'LOADING';
export const DATA_LOADED = 'LOADED';
export const DATA_NOT_FOUND = 'NOT_FOUND';
export const DATA_ERROR = 'ERROR';
export type DataStatus = DATA_INITIAL | DATA_LOADED | DATA_LOADING | DATA_NOT_FOUND | DATA_ERROR;


export type ListDataState<T> = {
  entities?: LookupTable<T>,
  status: DataStatus,
}






