import {RESET} from '../actions/global.actions';

// The initial state of the App
export const INITIAL_STATE = {};


export default function global(state = INITIAL_STATE, action) {
  switch (action.type) {

    case RESET:
      return INITIAL_STATE;

    default:
      return state;
  }
}

