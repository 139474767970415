import React from "react";
import {Route, Switch} from "react-router-dom";
import SecurityStandardDetailsScreen from "./modules/standards/screens/SecurityStandardDetailsScreen";
import SecurityStandardsScreen from "./modules/standards/screens/SecurityStandardsScreen";
import SecurityStandardsSearchScreen from "./modules/search/screens/SecurityStandardsSearchScreen";
import SecurityStandardGuidelineDetailsScreen from "./modules/standards/screens/SecurityStandardGuidelineDetailsScreen";


export default function Routes() {

  return (
    <Switch>
      <Route exact path="/security-standards/search" component={SecurityStandardsSearchScreen}/>
      <Route exact path="/security-standards/:standardId/guidelines/:id" component={SecurityStandardGuidelineDetailsScreen}/>
      <Route exact path="/security-standards/:id" component={SecurityStandardDetailsScreen}/>
      <Route exact path="/security-standards" component={SecurityStandardsScreen}/>
      {/*<Route exact path="/home" component={WelcomeScreen}/>*/}
      <Route exact path="/" component={SecurityStandardsScreen}/>
    </Switch>
  );
}
