import React, {Component} from 'react';
import map from "lodash/map";
import {connect} from "react-redux";
import {WhiteBox} from "amn/ui/containers/Boxes";
import {
  selectSecurityStandards,
  selectSecurityStandardsDataStatus
} from "amn/store/selectors/standards/security-standards.selectors";
import LoadingContainer from "amn/ui/containers/LoadingContainer";
import type {DataStatus} from "amn/store/reducers/common";
import {DATA_LOADING} from "amn/store/reducers/common";
import type {SecurityStandard} from "amn/model/standards/SecurityStandards";
import type {LookupTable} from "amn/model/common/Divers";
import {NavLink} from "react-router-dom";
import ListItem from "@material-ui/core/ListItem";
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import List from "@material-ui/core/List";
import {Tooltip} from "amn/ui/diverse/ToolTips";
import I18n from "amn/ui/i18n/I18n";
import {I18N_NS_STANDARDS} from "amn/common/i18n/I18nextConfig";
import {buildStandardUrl} from "../../../common/routes/standards-routes";

type Props = {
  securityStandards: LookupTable<SecurityStandard>;
  dataStatus: DataStatus;
};

type State = {};

class SecurityStandardsViewer extends Component<Props, State> {


  render() {
    const {securityStandards, dataStatus} = this.props;
    return (
      <WhiteBox p={2}>
        <LoadingContainer loading={dataStatus === DATA_LOADING}>
          <List>

            {
              map(securityStandards, (standard: SecurityStandard) => {
                return (
                  <Tooltip key={standard.id}
                           title={(
                             <I18n ns={I18N_NS_STANDARDS} i18nKey="standards.clickToOpen">
                               Click To Open
                             </I18n>
                           )}>
                    <ListItem button
                              component={NavLink}
                              key={standard.id}
                              to={buildStandardUrl(standard.id)}>

                      <ListItemIcon>
                        <AccountBalanceOutlinedIcon color="primary"/>
                      </ListItemIcon>
                      <ListItemText primary={standard.name}
                                    style={{wordBreak: "break-word"}}
                                    secondary={standard.description}/>
                    </ListItem>
                  </Tooltip>
                )
              })
            }
          </List>
        </LoadingContainer>
      </WhiteBox>
    );
  }

  static mapStateToProps = (state) => {
    return {
      dataStatus: selectSecurityStandardsDataStatus(state),
      securityStandards: selectSecurityStandards(state)
    }
  };
}


export default connect(SecurityStandardsViewer.mapStateToProps)(SecurityStandardsViewer);




