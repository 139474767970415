import React, {Component} from 'react';
import Box from "@material-ui/core/Box";
import includes from "lodash/includes";
import reduce from "lodash/reduce";
import SecurityStandardGuidelinesTree from "../tree/SecurityStandardGuidelinesTree";
import type {
  SecurityStandard,
  SecurityStandardGuideline,
  SecurityStandardGuidelineRelationships
} from "amn/model/standards/SecurityStandards";
import {SecurityStandardContext} from "../tree/SecurityStandardContexts";
import {
  selectSecurityStandardGuidelines,
  selectSecurityStandardGuidelinesRelationships
} from "amn/store/selectors/standards/security-standards.selectors";
import type {LookupTable} from "amn/model/common/Divers";
import WithBreakpoints from "amn/ui/containers/WithBreakpoints";
import {connect} from "react-redux";

type Props = {
  guideline: SecurityStandardGuideline;
  securityStandard: SecurityStandard;
  guidelines: LookupTable<SecurityStandardGuideline>;
  guidelinesRelationships: LookupTable<SecurityStandardGuidelineRelationships>;
  foundGuidelines: Array<string>;
};

type State = {
  guidelinesViewState: {
    [key: string]: {
      open: boolean,
      visible: boolean,
      highlighted: boolean,
    }
  },

};


class SecurityStandardsGuidelineRelationships extends Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      guidelinesViewState: this.prepareViewState()
    }

  }

  prepareViewState = () => {

    const guidelineId = this.props.guideline?.id;
    const ids = [guidelineId];
    const idsToShow = [guidelineId];
    const idsToOpen = [];
    const relationships: SecurityStandardGuidelineRelationships = this.props.guidelinesRelationships[guidelineId];
    idsToShow.push(...(relationships?.ancestorsIds || []))
    idsToShow.push(...(relationships?.descendentsIds || []))

    idsToOpen.push(...(relationships?.ancestorsIds || []))

    return reduce(this.props.guidelines, (res, guideline) => {
      res[guideline.id] = {
        open: !!includes(idsToOpen, guideline.id),
        visible: !!includes(idsToShow, guideline.id),
        highlighted: !!includes(ids, guideline.id)
      };
      return res;
    }, {});
  }

  getGuidelineViewState = (guidelineId: string) => {
    return this.state.guidelinesViewState[guidelineId] || {visible: true, open: false}
  }

  onGuidelineOpenStateChange = (guidelineId: string, open: boolean) => {
    const guidelinesViewState = this.state.guidelinesViewState;
    this.setState({
      guidelinesViewState: {
        ...guidelinesViewState,
        [guidelineId]: {
          ...(guidelinesViewState[guidelineId] || {visible: true}),
          open
        }
      }
    });
  }

  render() {

    const {securityStandard} = this.props;
    return (
      <SecurityStandardContext.Provider
        value={{
          securityStandard,
          getGuidelineViewState: this.getGuidelineViewState,
          onGuidelineOpenStateChange: this.onGuidelineOpenStateChange
        }}>

        { /*<WithBreakpoints>
          {(bps) => (
            <Box width={bps.smUp ? "100%" : "200%"}>
              <SecurityStandardGuidelinesTree
                securityStandard={securityStandard}/>
            </Box>
          )}
        </WithBreakpoints>*/}

            <Box >
              <SecurityStandardGuidelinesTree
                securityStandard={securityStandard}/>
            </Box>


      </SecurityStandardContext.Provider>
    );
  }

  static mapStateToProps = (state, props) => {
    return {
      guidelines: selectSecurityStandardGuidelines(state)(props.securityStandard?.id),
      guidelinesRelationships: selectSecurityStandardGuidelinesRelationships(state)(props.securityStandard?.id),
    }
  };
}

export default connect(SecurityStandardsGuidelineRelationships.mapStateToProps)(SecurityStandardsGuidelineRelationships);

