import React, {Component} from 'react';
import Box from "@material-ui/core/Box";

class AmnAuditorBanner extends Component {
  render() {
    return (
      <Box borderRadius="borderRadius">
        <a href="https://amnbrains.com/amn-auditor" target="_blank">
          <video loop muted autoPlay style={{maxHeight: 160, width: "100%"}}>
            <source className="default"
                    src="https://amnbrains.com/media/amn-auditor/banner"
                    type="video/mp4"/>
          </video>
        </a>
      </Box>
    );
  }
}

export default AmnAuditorBanner;