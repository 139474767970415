import React, {Component} from 'react';
import {connect} from "react-redux";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import Box from "@material-ui/core/Box";
import type {LookupTable} from "amn/model/common/Divers";
import GuidelineAddNoteButton from "./GuidelineAddNoteButton";
import Button from "@material-ui/core/Button";
import I18n from "amn/ui/i18n/I18n";
import {I18N_NS_STANDARDS} from "amn/common/i18n/I18nextConfig";
import Typography from "@material-ui/core/Typography";
import AssignmentAddIcon from "amn/ui/icons/AssignmentAddIcon";
import type {SecurityStandardGuideline, SecurityStandardUserNote} from "amn/model/standards/SecurityStandards";
import {selectGuidelineNotes} from "amn/store/selectors/notes/security-standards-user-notes.selectors";
import GuidelineUserNote from "./GuidelineUserNoteContent";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import {BackBox, WhiteBorderedBox} from "amn/ui/containers/Boxes";

type Props = {
  guideline: SecurityStandardGuideline;
  notes: LookupTable<SecurityStandardUserNote>;
};

type State = {};

class GuidelineUserNotes extends Component<Props, State> {

  state = {}


  render() {
    const {
      notes,
      guideline
    } = this.props;

    return (
      <div>
        <Box display="flex"
             alignItems="center"
             justifyContent="space-between">
          <Box display="flex"
               alignItems="center"
          >
            <Box display="flex"
                 mr={1}
                 color="primary.main"
                 alignItems="center">
              <AssignmentOutlinedIcon/>
            </Box>
            <Typography variant="subtitle1"
                        color="secondary">
              <I18n ns={I18N_NS_STANDARDS}
                    i18nKey="standardGuidelines.fields.notes.fieldName">
                Notes
              </I18n>
            </Typography>
          </Box>
          <GuidelineAddNoteButton renderButton={this.renderAddButton}
                                  guideline={guideline}/>
        </Box>
        <Box>
          {map(notes, (note: SecurityStandardUserNote) => {
            return (
              <Box key={note.id} mt={1}>
                <GuidelineUserNote note={note}/>
              </Box>
            );
          })}
          {isEmpty(notes) &&

          <WhiteBorderedBox mt={1}
                   p={2}
                   display={"flex"}
                   justifyContent={"center"}
                   color={"text.secondary"}>
            <I18n ns={I18N_NS_STANDARDS}
                  i18nKey="standardGuidelines.fields.notes.noNote">
              No Note
            </I18n>
          </WhiteBorderedBox>
          }
        </Box>
      </div>
    );
  }

  renderAddButton = (onClick) => {
    return (
      <Button onClick={onClick}
              variant="outlined"
              color="secondary"
              startIcon={<AssignmentAddIcon/>}>
        <I18n ns={I18N_NS_STANDARDS} i18nKey="standardNotes.add">
          Add a new Note
        </I18n>
      </Button>
    )
  }

  static mapStateToProps = (state, props) => {
    const {guideline} = props;
    return {
      notes: selectGuidelineNotes(state)(guideline?.id)
    }
  };
}


export default connect(GuidelineUserNotes.mapStateToProps)(GuidelineUserNotes);
