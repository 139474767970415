import store from "amn/store";


export const RESET = 'global/RESET';


export function reset(state) {
  store.dispatch({
    type: RESET,
    state
  });
}


