// @flow




import type {Reducer} from "./common";

export type SettingsState = {
  locale: string;
}

// FIXME circular store import
export const CHANGE_LOCALE = 'app/settings/CHANGE_LOCALE';
export const DEFAULT_LOCALE = 'fr';


const INITIAL_STATE: SettingsState = {
  locale: DEFAULT_LOCALE
};


const settings: Reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {

    case CHANGE_LOCALE:
      return {
        ...state,
        locale: action.locale
      };

    default:
      return state;
  }
};

export default settings;

