import {combineReducers} from 'redux';

import global from './global.reducer';
import settings from './settings.reducer';
import securityStandards from './standards/security-standards.reducer';
import iamUI from './iam/iam-ui.reducer';
import standardNotes from './notes/security-standard-user-notes.reducer';

export default function createReducer(injectedReducers) {

  const reduce = combineReducers({
    settings,
    securityStandards,
    iam: combineReducers({
      ui: iamUI
    }),
    user: combineReducers({
      standardNotes
    }),
    ...injectedReducers
  });
  return (state, action) => reduce(global(state, action), action);
}
