export type LanguageCode = "en" | "fr";


export type I18nTextValues = {
  [language: LanguageCode]: string
};

export type I18nText = {
  values: I18nTextValues
}


export const getI18nTextValue = (t: I18nText, language, fallbackLng) => {
  const values = t?.values || {};
  return values[language] || values[fallbackLng] || values["en"];
}
