export const KEY_CODES = {
  ESC: 27,
  ENTER: 13,
}


export const I18N_FR_LOCALE = 'fr';
export const I18N_EN_LOCALE = 'en';
export const I18N_DEFAULT_LOCALE = I18N_FR_LOCALE;

