import {withStyles} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";


export const WhiteBorderedBox = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    borderColor: theme.palette.divider,
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: theme.shape.borderRadius
  },
}))(Box);

export const WhiteBox = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.common.white,
    borderRadius: theme.shape.borderRadius
  },
}))(Box);

export const BackBox = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius
  },
}))(Box);

export const BackBorderedBox = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    borderRadius: theme.shape.borderRadius,
    borderColor: theme.palette.divider,
    borderWidth: 1,
    borderStyle: "solid",
  },
}))(Box);

export const HoverBox = withStyles((theme) => ({
  root: {
    cursor: "pointer",
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    },
  },
}))(Box);


export const stopPropagation = (e) => e.stopPropagation();
