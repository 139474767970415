import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';

import Routes from "./Routes";
import PrimaryAppBar from "./modules/common/components/PrimaryAppBar";
import Box from "@material-ui/core/Box";
import ClosableAlert from "./ui/diverse/ClosableAlert";
import Container from "@material-ui/core/Container";
import Hidden from "@material-ui/core/Hidden";
import I18n from "./ui/i18n/I18n";
import PrimaryFooter from "./modules/common/components/PrimaryFooter";
import LoginDialog from "./modules/iam/LoginDialog";
import AmnAuditorBanner from "./modules/common/components/AmnAuditorBanner";


export default function Root() {

  return (
    <div>
      <CssBaseline/>
      <PrimaryAppBar/>
      <Box mt={14}>
        <main>
          <Hidden smUp>
            <Container maxWidth={"lg"}>
              <ClosableAlert severity="warning">
                <I18n i18nKey="mobileNotFullySupportedAlertMessage">
                  Mobile Not Fully Supported
                </I18n>
              </ClosableAlert>
            </Container>
          </Hidden>
          <Box mt={2}>
            <Routes/>
          </Box>
        </main>
      </Box>
      <Container maxWidth={"lg"}>
        <Box mt={6} textAlign="center">
          <AmnAuditorBanner/>
        </Box>
      </Container>
      <Box mt={8}>
        <PrimaryFooter/>
      </Box>
      <LoginDialog/>
    </div>
  );
}



