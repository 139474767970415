const Config = {
  AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN,
  AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID,
  AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE,
  FIREBASE_CONFIG: JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG),
  GTM_ID: process.env.REACT_APP_GTM_ID
};


export default Config;
