import type {CreationResponse, ListResponse, UpdateResponse} from "amn/model/common/Responses";
import type {SecurityStandardUserNote} from "amn/model/standards/SecurityStandards";
import StandardsNotesOperations from "../firebase/StandardsNotesOperations";


const SecurityStandardsUserNotesBackendAdapter = {

  loadUserNotes: (userId: string): Promise<ListResponse<SecurityStandardUserNote>> => {
    return StandardsNotesOperations.loadUserNotes(userId);
  },


  createUserNote: (note: Partial<SecurityStandardUserNote>): Promise<CreationResponse> => {
    return StandardsNotesOperations.createUserNote(note);
  },

  updateUserNote: (note: SecurityStandardUserNote): Promise<UpdateResponse> => {
    return StandardsNotesOperations.updateUserNote(note);
  },

  deleteUserNote: (id: string): Promise<UpdateResponse> => {
    return StandardsNotesOperations.deleteUserNote(id);
  },

}

export default SecurityStandardsUserNotesBackendAdapter;
