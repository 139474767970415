import React, {useContext} from 'react';
import Box from "@material-ui/core/Box";
import ButtonBase from "@material-ui/core/ButtonBase";
import {makeStyles} from "@material-ui/core/styles";
import {MediumTooltip} from "amn/ui/diverse/ToolTips";
import HintIcon from "amn/ui/icons/HintIcon";
import Tooltip from "@material-ui/core/Tooltip";
import I18n from "amn/ui/i18n/I18n";
import CancelPresentationOutlinedIcon from "@material-ui/icons/CancelPresentationOutlined";
import ListAltOutlinedIcon from "@material-ui/icons/ListAltOutlined";
import type {SecurityStandardGuideline} from "amn/model/standards/SecurityStandards";
import GuidelineDocumentation from "./GuidelineDocumentation";
import GuidelineTreeItemRelatedVariants from "./GuidelineTreeItemRelatedVariants";
import {SecurityStandardContext} from "../SecurityStandardContexts";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import clsx from "clsx";
import GuidelineAddNoteButton from "./actions/GuidelineAddNoteButton";
import Hidden from "@material-ui/core/Hidden";
import {stopPropagation} from "amn/ui/containers/Boxes";
import GuidelineIcon from "./GuidelineIcon";

type Props = {
  guideline: SecurityStandardGuideline;
  showDetails: boolean;
  highlight: boolean;
  onToggleDetails: ()=>void;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: theme.spacing(1)
  },
  container: {
    display: "flex",
    justifyContent: "center",
    flex: 1,
    fontWeight: 500
    // fontW="fontWeightMedium"
    // justifyContent="center"
  },


  iconContainer: {
    display: "flex",
    alignItems: "center",
    color: theme.palette.primary.main
  },
  referenceContainer: {
    display: "flex",
    alignItems: "center",
    whiteSpace: "nowrap"
  },

  titleContainer: {
    flex: 1,
    display: "flex",
    marginLeft: theme.spacing(1),
    '&> div': {
      marginRight: theme.spacing(1),
    }
  },
  titleHighlighted: {
    // backgroundColor: "#ffffbc"
    backgroundColor: "#e1f4ff"
  }

}));

const GuidelineTreeItemHeader = ({guideline, showDetails, highlight, onToggleDetails}: Props) => {
  const classes = useStyles();
  const ctx = useContext(SecurityStandardContext)
  // const is
  const securityStandard = ctx.securityStandard;
  return (
    <div className={classes.root}>
      <div className={classes.container}>
        <Hidden smDown>
          <div className={classes.iconContainer}>
           <GuidelineIcon guideline={guideline}/>
          </div>
        </Hidden>
        <div className={classes.titleContainer}>
          <div className={classes.referenceContainer}>
            {guideline?.reference?.reference}
          </div>
          <div className={classes.referenceContainer}>
            -
          </div>
          <div className={clsx({[classes.titleHighlighted]: highlight})}>
            {guideline?.title}
          </div>
        </div>
      </div>

      <Hidden smDown>
        <Box ml={2}>
          <GuidelineTreeItemRelatedVariants
            securityStandard={securityStandard}
            guideline={guideline}/>
        </Box>
      </Hidden>
      <Box ml={2}>
        <GuidelineAddNoteButton guideline={guideline}/>
      </Box>
      <Box ml={1} display="flex"
           onClick={stopPropagation}
           alignItems="center">
        <MediumTooltip enterDelay={200} title={<GuidelineDocumentation guideline={guideline}/>} interactive>
          <InfoOutlinedIcon color={guideline?.description ? "secondary" : "disabled"}/>
        </MediumTooltip>
      </Box>
      <Box ml={1}>
        <Tooltip title={showDetails ? <I18n>Hide Details</I18n> : <I18n>Show Details</I18n>}>
          <ButtonBase onClick={onToggleDetails}>
            {showDetails ?
              <CancelPresentationOutlinedIcon color="secondary"/> :
              <ListAltOutlinedIcon color="secondary"/>
            }
          </ButtonBase>
        </Tooltip>
      </Box>

    </div>
  )
}


export default GuidelineTreeItemHeader;



