import React, {ReactNode} from 'react';
import {useTranslation} from "react-i18next";
import type {I18nText} from "./i18nText.types";
import {getI18nTextValue} from "./i18nText.types";
import {Tooltip} from "../diverse/ToolTips";
import {TooltipProps} from "@material-ui/core/Tooltip";


type Props = {
  t: I18nText;
  title?: ReactNode
} & TooltipProps;

const I18nTooltip = ({t, children, ...props}: Props) => {
  const {i18n} = useTranslation();

  const v = getI18nTextValue(t, i18n.language, i18n.fallbackLng);

  return (
    <Tooltip title={v} {...props}>
      {children}
    </Tooltip>
  );
};

export default I18nTooltip;



