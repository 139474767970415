import React from 'react';
import size from "lodash/size";
import isUndefined from "lodash/isUndefined";
import isNumber from "lodash/isNumber";
import TextField from "@material-ui/core/TextField";
import MuiAutocomplete from "@material-ui/lab/Autocomplete";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import Box from "@material-ui/core/Box";
import ArrowForwardOutlinedIcon from '@material-ui/icons/ArrowForwardOutlined';
import ButtonBase from "@material-ui/core/ButtonBase";
import Typography from "@material-ui/core/Typography";
import debounce from "lodash/debounce";
import {KEY_CODES} from "amn/common/Constants";
import {withStyles} from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";

export const Autocomplete = withStyles((theme) => ({
  popupIndicatorOpen: {
    transform: 'none',
  },
}))(MuiAutocomplete);

const SEARCH_BY_TEXT = "___SEARCH_TEXT___";
const SEARCH_BY_TEXT_OPTION = {id: SEARCH_BY_TEXT, group: "none"};

type Props = {
  disableClearable?: boolean;
  searchByText: (string)=> void;
  searchByOption: (string) => void;
  clearSelectedOptionLabel?: boolean;
  placeholder: string;
  initialSearchText?: string;
  suggest: (string) => Promise<Object>;
  TextFieldClass?: React.ComponentClass
}

type State = {
  searchText: string;
  open: boolean;
  options: Array<Object>;
  highlightedOption: any;
};

class SecurityStandardDetailsSearchControl extends React.Component<Props, State> {

  static defaultProps = {
    TextFieldClass: TextField,
    clearSelectedOptionLabel: false,
  }

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      value: null,
      searchText: props.initialSearchText || "",
      options: [SEARCH_BY_TEXT_OPTION],
      highlightedOption: null
    }
    if (props.initialSearchText) {
      this.suggest(props.initialSearchText);
    }
  }

  onKeyDown = (event) => {
    const {highlightedOption} = this.state;
    if (event.keyCode === KEY_CODES.ENTER && (!highlightedOption || highlightedOption.id === SEARCH_BY_TEXT)) {
      this.onChange({}, {...SEARCH_BY_TEXT_OPTION});
    }
  }

  onHighlightChange = (event, highlightedOption) => {
    this.setState({highlightedOption});
  }

  onTextChange = (event) => {
    const value = event?.target?.value;
    const searchText = isUndefined(value) || isNumber(value) ? this.state.searchText : value;

    this.setState({searchText, value: {...SEARCH_BY_TEXT_OPTION}}, async () => {
      this.suggest(searchText);
    });
  }

  onChange = (event, option) => {
    const searchText = this.isSearchOption(option) ? this.state.searchText : (option ? this.renderLabel(option) : "");
    this.setState({value: option, searchText});
    this.search(searchText, option);
  }

  isSearchOption = (option) => {
    return option?.id === SEARCH_BY_TEXT;
  }

  suggest = debounce(async (searchText) => {
    const results = await this.props.suggest(searchText || "");
    const options = size(results) > 0 ? [{...SEARCH_BY_TEXT_OPTION}, ...results] : [];
    this.setState({options});

  }, 200);

  search = (searchText, value) => {
    if (!value) {
      this.props.searchByText("")
    } else if (this.isSearchOption(value)) {
      this.props.searchByText(searchText);
    } else {
      this.props.searchByOption(value)
      if (this.props.clearSelectedOptionLabel) {
        this.setState({value: null, searchText: ""});
      }
    }
    this.setState({open: false});
  }

  render() {
    const {searchText, options, open, value} = this.state;
    const {TextFieldClass, disableClearable, placeholder} = this.props;
    return (
      <Box position="relative" alignItems="center">
        <Autocomplete
          fullWidth
          onHighlightChange={this.onHighlightChange}
          open={open && !!searchText}
          size={"small"}
          value={value}
          autoHighlight
          groupBy={(option) => option.group === "none" ? undefined : option?.standard?.name || "Résultats rapides"}
          getOptionLabel={this.renderLabel}
          options={options}
          filterOptions={(x) => x}
          openOnFocus={false}
          includeInputInList
          onOpen={() => this.setState({open: true})}
          onClose={() => this.setState({open: false})}
          onInputChange={this.onTextChange}
          disableClearable={disableClearable}
          clearOnBlur={false}
          popupIcon={<Box width={24}/>}
          onChange={this.onChange}
          renderInput={(params) => {
            this.inputRef = params.inputProps.ref
            const overriddenParams = {
              ...params,
              inputProps: {
                ...params.inputProps,
                value: searchText
              }
            };
            return (
              <TextFieldClass {...overriddenParams}
                              variant="outlined"
                              placeholder={placeholder}
                              onKeyDown={this.onKeyDown}
              />
            );
          }}
          renderOption={this.renderOption}
        />
        <Box position="absolute"
             top={0}
             right={4}
             height="100%"
             display="flex"
             alignItems="center">
          <IconButton size={"small"} onClick={() => this.search(searchText, value)}>
            <SearchOutlinedIcon/>
          </IconButton>
        </Box>

      </Box>
    );
  }

  renderLabel = (option) => {
    return option?.id === SEARCH_BY_TEXT ? (this.state.searchText || "") : `${option.title}`;
  }

  renderOption = (option) => {

    if (option.id === SEARCH_BY_TEXT) {
      return (
        <Box width={"100%"} pt={1}
             pb={1}
             display="flex"
             color="secondary.main">
          <Box display="flex"
               flex={1}
               alignItems="center">
            <Box mr={1}
                 display="flex"
                 alignItems="center">
              <SearchOutlinedIcon fontSize={"small"}/>
            </Box>
            <Box fontSize={"body2.fontSize"}>
              Chercher tous les résultats pour <b>{this.state.searchText}</b>
            </Box>
            <Box fontWeight="fontWeightMedium" fontSize={"body2.fontSize"}>

            </Box>
          </Box>
          <ButtonBase>
            <ArrowForwardOutlinedIcon fontSize={"small"}/>
          </ButtonBase>
        </Box>
      )
    } else {
      return (
        <div style={{display: "flex"}}>
          <div style={{marginRight: "8px", whiteSpace: "nowrap", display: "flex", alignItems: "center"}}>
            <Typography variant={"body2"}>
              {option?.reference?.reference}
            </Typography>
          </div>
          <div style={{marginRight: "8px", display: "flex", alignItems: "center"}}>
            -
          </div>
          <Typography variant={"body2"}>
            {option?.title}
          </Typography>
        </div>
      )
    }
  }
}


export default SecurityStandardDetailsSearchControl;
