import React, {Component} from 'react';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import I18n from "amn/ui/i18n/I18n";
import SecurityStandardsSearchResult from "../components/SecurityStandardsSearchResult";
import {getCurrentLocationQueryParam} from "../../../common/utils/LocationUtils";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import {NavLink} from "react-router-dom";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import {I18N_NS_STANDARDS} from "../../../common/i18n/I18nextConfig";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import {SECURITY_STANDARDS_BASE_URL} from "../../common/routes/standards-routes";


class SecurityStandardsSearchScreen extends Component {

  render() {
    const q = getCurrentLocationQueryParam("q")
    return (
      <Container>
        <Box mb={4}>
          <Breadcrumbs separator="›">
            <Link color="inherit" component={NavLink}
                  to={SECURITY_STANDARDS_BASE_URL}>
              <Box display="flex"
                   fontSize={"body2.fontSize"}
                   alignItems="center">
                <Box mr={1}
                     display="flex"
                     alignItems="center">
                  <HomeOutlinedIcon fontSize="small"/>
                </Box>
                <I18n ns={I18N_NS_STANDARDS} i18nKey="standards.all">
                  Security Standards
                </I18n>
              </Box>
            </Link>

            <Box display="flex"
                 fontSize={"body2.fontSize"}
                 alignItems="center">
              <Box mr={1}
                   display="flex"
                   alignItems="center">
                <SearchOutlinedIcon fontSize="small"/>
              </Box>
              Recherche
            </Box>
          </Breadcrumbs>
        </Box>
        <Box mb={2}>
          <Typography variant="h5" gutterBottom>
            <I18n i18nKey="searchResults">
              Results
            </I18n>
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} zeroMinWidth>
            {/*<SecurityStandardsViewer/>*/}
            {q ?
              <SecurityStandardsSearchResult searchText={q} key={q}/>
              :
              <div>No Results</div>
            }
          </Grid>
        </Grid>
      </Container>
    );
  }
}


export default SecurityStandardsSearchScreen;



