import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import MuiTooltip, {TooltipProps} from "@material-ui/core/Tooltip";


export const Tooltip = ({children, ...props}: TooltipProps) => {
  if (props.title) {
    return (
      <MuiTooltip {...props}>
        {children}
      </MuiTooltip>
    )
  }
  return (
    <React.Fragment>
      {children}
    </React.Fragment>
  )
}


export const MediumTooltip = withStyles((theme) => ({
  tooltip: {
    maxWidth: 800
  },
}))(Tooltip);
