import React from 'react';
import {borderColor} from "amn/modules/theme/theme";
import TextField from "@material-ui/core/TextField";
import {withStyles} from "@material-ui/core/styles";
import {withRouter} from "react-router";
import SecurityStandardDetailsSearchControl
  from "amn/modules/standards/components/common/SecurityStandardDetailsSearchControl";
import {getSecurityStandardsIndex, GUIDELINES_INDEX} from "amn/backend/local/standards/SecurityStandardsData";
import {getCurrentLocationQueryParam} from "amn/common/utils/LocationUtils";
import {buildGuidelineUrl, buildStandardSearchUrl} from "../routes/standards-routes";
import type {SecurityStandardGuideline} from "amn/model/standards/SecurityStandards";

const FlatTextField = withStyles((theme) => ({
  root: {
    '& label.Mui-focused': {
      color: 'green',
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: theme.palette.background.default, //"#0d86d0",
      color: "inherit",
      paddingLeft: theme.spacing(1),
      '@media (hover: none)': {
        '&:hover fieldset': {
          borderColor
        }
      },

      '& fieldset': {
        borderColor: "transparent"
      },
    },
  },
}))(TextField);


class PrimaryAppBarSearchControl extends React.Component {

  suggest = async (searchText) => {
    if (searchText) {
      const index = await getSecurityStandardsIndex();
      return await index.search(searchText);
    }
  };

  searchByOption = (guideline: SecurityStandardGuideline) => {
    const guidelineId = guideline?.id;
    const standardId = guideline?.standard?.id;
    const {history} = this.props;
    history.push(buildGuidelineUrl(standardId, guidelineId));
  }

  searchByText = (searchText) => {
    if(searchText) {
      const {history} = this.props;
      history.push(buildStandardSearchUrl(searchText));
    }
  }


  render() {
    const q = getCurrentLocationQueryParam("q")
    return (
      <SecurityStandardDetailsSearchControl TextFieldClass={FlatTextField}
                                            clearSelectedOptionLabel
                                            initialSearchText={q}
                                            placeholder={"Chercher dans tous les référentiels"}
                                            searchByText={this.searchByText}
                                            searchByOption={this.searchByOption}
                                            suggest={this.suggest}/>
    );
  }

}

export default withRouter(PrimaryAppBarSearchControl);
