export type PersistentEntity = {
  id: string;
  creationDate: string;
  lastModificationDate: string;
}

export type PersistentReferenceableEntity = PersistentEntity & {
  businessId: string;
}


export const equalsById = (e1: PersistentEntity, e2: PersistentEntity) => {
  return e1 && e2 && e1.id && e1.id === e2.id;
}

export const getEntityId = (object) => object && object.id;
