import {getI18nTextValue, I18nText} from "../i18n/i18nText.types";
import React from "react";
import find from "lodash/find";
import isNumber from "lodash/isNumber";

export type GenericOption<T> = {
  value: T,
  label: I18nText,
  description?: I18nText,
  icon?: string | React.Component;
  iconColor?: string;
  weight?: number;
}

export const getOptionByValue = <T>(value: T, options: Array<GenericOption<T>>): GenericOption<T> => {
  if (value) {
    return find(options, option => option.value === value);
  }
}

export const getOptionLabelByValue = <T>(value: T, options: Array<GenericOption<T>>, language = "en"): GenericOption<T> => {
  const option = getOptionByValue(value, options);
  return getOptionLabel(option, language);
}

export const getOptionLabel = <T>(option: GenericOption<T>, language = "en"): string => {
  return getI18nTextValue(option?.label, language);
}

export const sortCompareOptions = <T>(o1: GenericOption<T>, o2: GenericOption<T>): GenericOption<T> => {
  if (isNumber(o1?.weight) && !isNumber(o2?.weight)) {
    return 1;
  }
  if (!isNumber(o1?.weight) && isNumber(o2?.weight)) {
    return -1;
  }
  return (isNumber(o1?.weight) && isNumber(o2?.weight)) ?
    (o1?.weight - o2?.weight)
    : 0;
}

export const makeOptionValueSortComparator = <T>(getOption) => {
  return (v1: T, v2: T): number => {
    const o1 = getOption(v1);
    const o2 = getOption(v2);

    return sortCompareOptions(o1, o2);
  };
};
