import React, {Component} from 'react';
import Box from "@material-ui/core/Box";
import {NavLink} from "react-router-dom";
import {Helmet} from "react-helmet";
import {
  makeSecurityStandardGuidelineSelector,
  selectSecurityStandards
} from "amn/store/selectors/standards/security-standards.selectors";
import {connect} from "react-redux";
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import Container from "@material-ui/core/Container";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import I18n from "amn/ui/i18n/I18n";
import {I18N_NS_STANDARDS} from "amn/common/i18n/I18nextConfig";
import {loadStandardGuidelines} from "amn/store/actions/standards/security-standards.actions";
import LoadingContainer from "amn/ui/containers/LoadingContainer";
import type {SecurityStandard, SecurityStandardGuideline} from "amn/model/standards/SecurityStandards";
import HintIcon from "amn/ui/icons/HintIcon";
import SecurityStandardGuidelineDetails from "./SecurityStandardGuidelineDetails";
import {securityStandardGuidelineReference} from "amn/model/standards/SecurityStandards";
import {buildStandardUrl, SECURITY_STANDARDS_BASE_URL} from "amn/modules/common/routes/standards-routes";


type Props = {
  guideline: SecurityStandardGuideline;
  securityStandard: SecurityStandard;
};

class SecurityStandardGuidelineDetailsViewer extends Component<Props> {

  state = {
    loading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const {securityStandard} = this.props;
    loadStandardGuidelines(securityStandard.id)
      .then(() => {
        this.setState({loading: false})
      })
      .catch(() => {
        this.setState({loading: false})
      });
  }


  render() {
    const {loading} = this.state;
    const {guideline} = this.props;
    return (
      <LoadingContainer loading={loading}>
        {guideline && this.renderGuideline()}

        {!guideline &&
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"} minHeight={400}>
          Not Found
        </Box>

        }
      </LoadingContainer>
    );
  }

  renderGuideline = () => {
    const {securityStandard, guideline} = this.props;
    return (
      <Container maxWidth={"lg"}>
        <Helmet>
          <title lang="fr">{securityStandard?.name} | {securityStandardGuidelineReference(guideline)} - {guideline?.title}</title>
          <meta name="description"
                content={`Détails sur l'exigence ${securityStandardGuidelineReference(guideline)} - ${guideline?.title} du Référentiel ${securityStandard?.name}. ${guideline?.description}`}/>
          <meta name="keywords"
                content={`Référentiel sécurité, Référentiel cybersécurité, ${securityStandard?.name}, ${securityStandard?.description}, ${securityStandardGuidelineReference(guideline)}, ${guideline?.title}`}/>
        </Helmet>
        <Box mb={2}>
          <Box mb={2}>
            <Breadcrumbs separator="›">
              <Link color="inherit" component={NavLink}
                    to={SECURITY_STANDARDS_BASE_URL}>
                <Box display="flex"
                     fontSize={"body2.fontSize"}
                     alignItems="center">
                  <Box mr={1}
                       display="flex"
                       alignItems="center">
                    <HomeOutlinedIcon fontSize="small"/>
                  </Box>
                  <I18n ns={I18N_NS_STANDARDS} i18nKey="standards.all">
                    Security Standards
                  </I18n>
                </Box>
              </Link>
              <Link color="inherit" component={NavLink}
                    to={buildStandardUrl(securityStandard.id)}>
                <Box display="flex"
                     fontSize={"body2.fontSize"}
                     alignItems="center">
                  <Box mr={1}
                       display="flex"
                       alignItems="center">
                    <AccountBalanceOutlinedIcon fontSize="small"/>
                  </Box>
                  {securityStandard?.name}
                </Box>
              </Link>

              <Box display="flex"
                   fontSize={"body2.fontSize"}
                   color="primary.main"
                   alignItems="center">
                <Box mr={1}
                     display="flex"
                     alignItems="center">
                  <HintIcon fontSize="small"/>
                </Box>
                {securityStandardGuidelineReference(guideline)} - {guideline?.title}
              </Box>

            </Breadcrumbs>
          </Box>
          <Box mt={5}>
            <SecurityStandardGuidelineDetails guideline={guideline}
                                              securityStandard={securityStandard}/>
          </Box>
        </Box>

      </Container>
    );
  }

  static mapStateToProps = (state, props) => {
    const securityStandards = selectSecurityStandards(state);
    const securityStandardId = props.securityStandardId;
    const guidelineId = props.guidelineId;
    return {
      securityStandard: securityStandards[securityStandardId],
      guideline: makeSecurityStandardGuidelineSelector(state)(guidelineId),
    }
  };
}


export default connect(SecurityStandardGuidelineDetailsViewer.mapStateToProps)(SecurityStandardGuidelineDetailsViewer);

