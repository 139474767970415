import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from 'amn/App';
import "amn/modules/common/analytics/gtm";
import * as serviceWorker from './serviceWorker';


ReactDOM.render(
  <React.Fragment>
    <App/>
  </React.Fragment>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept('./amn/App', () => {
    const NextApp = require('./amn/App').default
    ReactDOM.render(
      <React.Fragment>
        <NextApp/>
      </React.Fragment>,
      document.getElementById('root')
    );
  })
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

