import React, {Component} from 'react';
import {getSecurityStandardsIndex, GUIDELINES_INDEX} from "amn/backend/local/standards/SecurityStandardsData";
import groupBy from "lodash/groupBy";
import map from "lodash/map";
import type {SecurityStandardGuideline} from "amn/model/standards/SecurityStandards";
import Box from "@material-ui/core/Box";
import SecurityStandardsSearchResultPerStandard from "./SecurityStandardsSearchResultPerStandard";
import {getEntityId} from "amn/model/common/Entity";
import LoadingContainer from "amn/ui/containers/LoadingContainer";

type Props = {
  searchText: string;
};

type State = {
  loading: boolean;
  entries: {
    [key: string]: Partial<SecurityStandardGuideline> & { standard: { id: string, name: string } };
  }
};

class SecurityStandardsSearchResult extends Component<Props, State> {

  state = {
    entries: {},
    loading: true
  }

  componentDidMount() {
    this.loadSearchResults();
  }

  loadSearchResults = async () => {
    const index = await getSecurityStandardsIndex();
    const results = await index.search(this.props.searchText);
    const entries = groupBy(results, 'standard.id');
    this.setState({entries, loading: false});
  }

  render() {
    return (
      <LoadingContainer loading={this.state.loading}>
        <Box mt={1}>
          {map(this.state.entries, (guidelines, standardId) => {
            return (
              <Box mb={3} key={standardId}>
                <SecurityStandardsSearchResultPerStandard
                  securityStandardId={standardId}
                  foundGuidelines={map(guidelines, getEntityId)}/>
              </Box>
            )
          })}
        </Box>
      </LoadingContainer>
    );
  }
}


export default SecurityStandardsSearchResult;



