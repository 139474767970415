import React, {Component} from 'react';
import {connect} from "react-redux";
import Box from "@material-ui/core/Box";
import map from "lodash/map";
import size from "lodash/size";
import get from "lodash/get";
import truncate from "lodash/truncate";
import toMaterialStyle from "material-color-hash";
import {
  selectSecurityStandardVariants,
  selectSecurityStandardVariantsDataStatus
} from "amn/store/selectors/standards/security-standards.selectors";
import type {
  SecurityStandard,
  SecurityStandardGuideline,
  SecurityStandardVariant
} from "amn/model/standards/SecurityStandards";
import type {LookupTable} from "amn/model/common/Divers";
import type {DataStatus} from "amn/store/reducers/common";

type Props = {
  securityStandard: SecurityStandard;
  variants: LookupTable<SecurityStandardVariant>;
  dataStatus: DataStatus;
  guideline: SecurityStandardGuideline;
};

type State = {};

class GuidelineTreeItemRelatedVariants extends Component<Props, State> {

  render() {
    const {guideline, variants} = this.props;
    return (
      <Box display="flex">
        {size(variants) > 1 && variants && map(guideline.relatedVariants, variantId => {
          const variant = variants[variantId];
          if (variant) {
            const style = toMaterialStyle(variant.id);
            return (
              <Box key={variantId}
                   ml={1}
                   fontWeight="fontWeightBold"
                   borderRadius="borderRadius"
                   color={style.backgroundColor}
                   borderColor={style.backgroundColor}
                   border={1}
                   pr={1}
                   pl={1}
                   fontSize={8}
                   p={0.5}>
                {truncate(variant.name, {'length': 12})}
              </Box>
            );
          }
        })
        }
      </Box>
    );
  }

  static mapStateToProps = (state, props) => {
    const securityStandardId = get(props.securityStandard, "id");
    return {
      dataStatus: selectSecurityStandardVariantsDataStatus(state)(securityStandardId),
      variants: selectSecurityStandardVariants(state)(securityStandardId)
    }
  };
}


export default connect(GuidelineTreeItemRelatedVariants.mapStateToProps)(GuidelineTreeItemRelatedVariants);



