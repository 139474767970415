import React from 'react';
import ButtonBase from "@material-ui/core/ButtonBase";
import IndeterminateCheckBoxOutlinedIcon from "@material-ui/icons/IndeterminateCheckBoxOutlined";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import {BoxProps} from "@material-ui/core/Box/Box";
import LazyCollapse from "./LazyCollapse";
import {makeStyles} from "@material-ui/core/styles";

type Props = {
  label: React.ReactNode,
  children?: React.ReactNode,
  secondaryLabel?: React.ReactNode,
  initiallyOpen?: boolean,
  hideFoldButton?: boolean,
  onToggle: ()=> void;
} & BoxProps;

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    cursor: "pointer",
    '&:hover': {
      backgroundColor: theme.palette.action.hover
    },
  },
  foldButtonContainer: {
    display: "flex",
    alignItems: "center",
    marginLeft: theme.spacing(1)
  },
  hiddenFoldButton: {
    width: "20px"
  },
  subContentContainer: {
    paddingLeft: theme.spacing(2)
  },
  subContent: {
    paddingLeft: theme.spacing(2),
    borderLeftWidth: 1,
    borderColor: theme.palette.divider,
    borderLeftStyle: "solid"
  }
}));

const TreeItem = ({
                    label,
                    children,
                    secondaryLabel,
                    hideFoldButton = false,
                    open = false,
                    onToggle,
                    ...others
                  }: Props) => {
  const classes = useStyles();
  return (
    <div {...others}>
      <div className={classes.headerContainer} onClick={onToggle}>
        <div className={classes.foldButtonContainer}>
          {hideFoldButton ?
            <div className={classes.hiddenFoldButton}/>
            :
            <ButtonBase>
              {open ?
                <IndeterminateCheckBoxOutlinedIcon fontSize="small"/> :
                <AddBoxOutlinedIcon fontSize="small"/>
              }
            </ButtonBase>
          }

        </div>
        {label}
      </div>
      {secondaryLabel &&
      <div>
        {secondaryLabel}
      </div>
      }
      <LazyCollapse in={open}>
        <div className={classes.subContentContainer}>
          <div className={classes.subContent}>
            {children}
          </div>
        </div>
      </LazyCollapse>
    </div>
  )
}


export default TreeItem;



