import "fontsource-poppins/500-normal.css"
import "fontsource-poppins/400-normal.css"

import createMuiTheme from '@material-ui/core/styles/createMuiTheme';


// styles
// const borderColor = "#eaf2f7";
export const mainColor = '#109CF1';
export const borderColor = "#e3e6ed";
const successColor = "#2ed47a";
const errorColorPalette = {
  contrastText: "#FFFFFF",
  main: "#F7685B",
  dark: "#d64336",
};

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: mainColor,
      contrastText: "#FFFFFF"
    },
    secondary: {
      main: '#054582',
      contrastText: "#FFFFFF"
    },
    background: {
      default: '#F5F6F8',
    },
    error: errorColorPalette,
    warning: {
      main: "#FFB946"
    },
    success: {
      main: successColor
    },
    text: {
      primary: '#192A3E',
      secondary: '#8497b3',
      disabled: '#b2becd'
    },
    divider: borderColor,
    inputOutline: borderColor,
    // error: {
    //   main: red.A400,
    // },
    // background: {
    //   default: '#fff',
    // },
    action: {
      active: "#60738a",
      hover: "#f3f4f7",
      // hover_: "rgba(0, 0, 0, 0.04)",
      selected: "#e8ecf1",
      // selected: "rgba(0, 0, 0, 0.08)",
      disabled: "#aab8c2",
      // disabled_: "rgba(0, 0, 0, 0.26)",
      disabledBackground: "#dde4ea",
      // disabledBackground_: "rgba(0, 0, 0, 0.12)",
      focus: "#dde4ea",
    }
  },
  typography: {
    // fontFamily: ["Poppins", "Roboto", "Helvetica", "Arial", "sans-serif"],
    subtitle1: {
      fontWeight: 500
    }
  },
  overrides: {
    MuiStepContent: {
      root: {
        borderLeft: "1px solid " + borderColor
      }
    },
    MuiTableCell: {
      root: {
        borderBottom: "1px solid " + borderColor,
      },
    },
    MuiOutlinedInput: {
      root: {
        '@media (hover: none)': {
          '&:hover $notchedOutline': {
            borderColor
          }
        },
        '&:hover $notchedOutline': {
          borderColor: "#76ceff"
        },
        // backgroundColor: "#f5f6f878"
      },
      notchedOutline: {
        borderColor,
        // borderWidth: 2
      }
    },
    MuiRadio: {
      root: {
        // color: mainColor
      }
    },
    MuiChip: {
      root: {
        backgroundColor: "#e8ecf1"
      }
    },
    MuiButton: {
      contained: {
        boxShadow: "initial",
        '&:hover': {
          boxShadow: "initial",
          // Reset on touch devices, it doesn't add specificity
          '@media (hover: none)': {
            boxShadow: "initial",
          }
        },
        '&$focusVisible': {
          boxShadow: "initial"
        },
        '&:active': {
          boxShadow: "initial"
        },
        '&$disabled': {
          boxShadow: "initial"
        }
      }
    },
    MuiSwitch: {
      track: {
        backgroundColor: "#b2becd",
        opacity: 1
      }
    },
    MUIRichTextEditor: {
      container: {
        border: "1px solid " + "#f1f3f7",
        borderRadius: "3px"
      },
      editor: {
        padding: "8px",
      },
      toolbar: {
        borderBottom: "1px solid " + "#f1f3f7",
        background: "#f9f9f9"
      },
      placeHolder: {
        position: "initial",
        padding: "6px 8px 0 8px",
      }
    }
  }
});

export default theme;


