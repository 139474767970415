import React, {Component} from 'react';
import type {SecurityStandardGuideline} from "amn/model/standards/SecurityStandards";
import {
  getGuidelineTypeDescription,
  isGuidelineCheckPoint,
  isGuidelineWithStatus
} from "amn/model/standards/SecurityStandards";
import Box from "@material-ui/core/Box";
import AssignmentOutlinedIcon from "@material-ui/icons/AssignmentOutlined";
import BeenhereOutlinedIcon from "@material-ui/icons/BeenhereOutlined";
import HintIcon from "amn/ui/icons/HintIcon";
import {SvgIconProps} from "@material-ui/core/SvgIcon/SvgIcon";
import I18nTooltip from "amn/ui/i18n/I18nTooltip";

type Props = {
  guideline: SecurityStandardGuideline;
  checkpoint?: boolean;
  iconProps?: SvgIconProps;
};

type State = {};

class GuidelineIcon extends Component<Props, State> {

  static defaultProps = {
    iconProps: {}
  }

  render() {
    const {guideline} = this.props;
    const description = getGuidelineTypeDescription(guideline);
    return (
      <I18nTooltip t={description}>
        {this.renderIcon()}
      </I18nTooltip>
    );
  }

  renderIcon = ()=> {
    const {guideline, iconProps} = this.props;
    if (isGuidelineWithStatus(guideline)) {
      return (
        <Box display="flex"
             color="secondary.main"
             alignItems="center">
          <AssignmentOutlinedIcon {...iconProps}/>
        </Box>
      )
    } else if (isGuidelineCheckPoint(guideline)) {
      return (
        <Box display="flex"
             color="error.main"
             alignItems="center">
          <BeenhereOutlinedIcon fontSize="small" {...iconProps}/>
        </Box>
      )
    } else {
      return (
        <Box display="flex"
             color="primary.main"
             alignItems="center">
          <HintIcon {...iconProps}/>
        </Box>
      );
    }
  }
}


export default GuidelineIcon;



