import React, {Component} from 'react';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import theme from "./theme";

class ThemeProvider extends Component {

  render() {
    return (
      <MuiThemeProvider theme={theme}>
        {this.props.children}
      </MuiThemeProvider>
    );
  }
}

export default ThemeProvider;



