import React from 'react';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Collapse from '@material-ui/core/Collapse';
import CloseIcon from '@material-ui/icons/Close';


export default function ClosableAlert(props) {
  const [open, setOpen] = React.useState(true);

  return (
    <Collapse in={open}>
      <Alert {...props}
             action={
               <IconButton
                 aria-label="close"
                 color="inherit"
                 size="small"
                 onClick={() => {
                   setOpen(false);
                 }}>
                 <CloseIcon fontSize="inherit"/>
               </IconButton>
             }/>
    </Collapse>
  );
}
