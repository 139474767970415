import firebaseAuth from "./FirebaseAuth";
import auth0Client from "./Auth0Client";
import Config from "../../Config";
import {Auth0Lock} from "auth0-lock";



export const signInWithCustomToken = async (idToken) => {
  const response = await fetch('/api/auth/', {
    headers: {
      'Authorization': `Bearer ${idToken}`,
    },
  });

  const data = await response.json();
  return await firebaseAuth.signInWithCustomToken(data.firebaseToken);
}

export const signIn = async () => {
  // TODO handle timeout error
  // await auth0Client.loginWithPopup({}, {timeoutInSeconds: 3600});

  // const claims = await auth0Client.getIdTokenClaims({scope: 'openid profile'});
  // await signInWithCustomToken(claims.__raw);
  // const user = await auth0Client.getUser();
  // return await firebaseAuth.updateProfile(user);
}


export const updateProfile = async (user) => {
  return await firebaseAuth.updateProfile(user);
}

export const signOut = async () => {
  const auth0Lock = new Auth0Lock(
    Config.AUTH0_CLIENT_ID,
    Config.AUTH0_DOMAIN,
  );

  await firebaseAuth.signOut();
  auth0Lock.logout({returnTo: window.location.origin});
  // return Promise.all([
  //   firebaseAuth.signOut(),
  //   // Promise.resolve(auth0Lock.logout({returnTo: window.location.origin}))
  //   auth0Client.logout({returnTo: window.location.origin}),
  // ]);

}
