import React, {Component} from 'react';
import Box from "@material-ui/core/Box";
import forEach from "lodash/forEach";
import includes from "lodash/includes";
import reduce from "lodash/reduce";
import SecurityStandardGuidelinesTree from "../../standards/components/tree/SecurityStandardGuidelinesTree";
import {WhiteBox} from "amn/ui/containers/Boxes";
import type {
  SecurityStandard,
  SecurityStandardGuideline,
  SecurityStandardGuidelineRelationships
} from "amn/model/standards/SecurityStandards";
import OpenInNewOutlinedIcon from '@material-ui/icons/OpenInNewOutlined';
import {SecurityStandardContext} from "../../standards/components/tree/SecurityStandardContexts";
import {
  selectSecurityStandardGuidelines,
  selectSecurityStandardGuidelinesRelationships,
  selectSecurityStandards
} from "amn/store/selectors/standards/security-standards.selectors";
import {connect} from "react-redux";
import {loadStandardGuidelines} from "amn/store/actions/standards/security-standards.actions";
import LoadingContainer from "amn/ui/containers/LoadingContainer";
import Typography from "@material-ui/core/Typography";
import type {LookupTable} from "amn/model/common/Divers";
import {NavLink} from "react-router-dom";
import Link from "@material-ui/core/Link";
import CollapsiblePanel from "amn/ui/containers/CollapsiblePanel";
import WithBreakpoints from "amn/ui/containers/WithBreakpoints";
import {buildStandardUrl} from "../../common/routes/standards-routes";

type Props = {
  securityStandardId: string;
  securityStandard: SecurityStandard;
  guidelines: LookupTable<SecurityStandardGuideline>;
  guidelinesRelationships: LookupTable<SecurityStandardGuidelineRelationships>;
  foundGuidelines: Array<string>;
};

type State = {
  loading: boolean;
  guidelinesViewState: {
    [key: string]: {
      open: boolean,
      visible: boolean,
      highlighted: boolean,
    }
  },

};


class SecurityStandardsSearchResultPerStandard extends Component<Props, State> {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      guidelinesViewState: {}
    }
  }

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const {securityStandardId} = this.props;
    loadStandardGuidelines(securityStandardId)
      .then(this.show)
      .catch(this.show);
  }

  show = () => {
    this.setState({
      loading: false,
      guidelinesViewState: this.prepareViewState()
    })
  }

  prepareViewState() {

    const ids = this.props.foundGuidelines;
    const idsToShow = [...ids];
    const idsToOpen = [];
    forEach(ids, id => {
      const relationships: SecurityStandardGuidelineRelationships = this.props.guidelinesRelationships[id];
      idsToShow.push(...(relationships?.ancestorsIds || []))
      idsToShow.push(...(relationships?.descendentsIds || []))

      idsToOpen.push(...(relationships?.ancestorsIds || []))
    });

    return reduce(this.props.guidelines, (res, guideline) => {
      res[guideline.id] = {
        open: !!includes(idsToOpen, guideline.id),
        visible: !!includes(idsToShow, guideline.id),
        highlighted: !!includes(ids, guideline.id)
      };
      return res;
    }, {});
  }

  getGuidelineViewState = (guidelineId: string) => {
    return this.state.guidelinesViewState[guidelineId] || {visible: true, open: false}
  }

  onGuidelineOpenStateChange = (guidelineId: string, open: boolean) => {
    const guidelinesViewState = this.state.guidelinesViewState;
    this.setState({
      guidelinesViewState: {
        ...guidelinesViewState,
        [guidelineId]: {
          ...(guidelinesViewState[guidelineId] || {visible: true}),
          open
        }
      }
    });
  }

  render() {

    const {securityStandard} = this.props;
    const {loading} = this.state;
    return (
      <LoadingContainer loading={loading}>
        <SecurityStandardContext.Provider
          value={{
            securityStandard,
            getGuidelineViewState: this.getGuidelineViewState,
            onGuidelineOpenStateChange: this.onGuidelineOpenStateChange
          }}>
          <WithBreakpoints>
            {(bps) => (
              <WhiteBox p={4} width={bps.smUp ? "100%" : "200%"}>
                <CollapsiblePanel title={this.renderTitle()}>
                  <Box pb={1} mt={4} mb={4} pl={1} pr={2}>
                    <SecurityStandardGuidelinesTree
                      securityStandard={securityStandard}/>
                  </Box>
                </CollapsiblePanel>

              </WhiteBox>
            )}
          </WithBreakpoints>
        </SecurityStandardContext.Provider>
      </LoadingContainer>
    );
  }

  renderTitle() {
    const {securityStandard} = this.props;
    return (
      <Box display="flex">
        <Link color="inherit"
              component={NavLink}
              target={"_blank"}
              to={buildStandardUrl(securityStandard.id)}>
          <Box display="flex"
               alignItems={"center"}>
            <Box mr={1} display="flex"
                 alignItems={"center"}>
              <OpenInNewOutlinedIcon fontSize={"small"}
                                     color={"primary"}/>
            </Box>
            <Typography variant={"h6"} color={"primary"}>
              {securityStandard.name}
            </Typography>
          </Box>
        </Link>
      </Box>
    );
  }

  static mapStateToProps = (state, props) => {
    const securityStandards = selectSecurityStandards(state);
    return {
      securityStandard: securityStandards[props.securityStandardId],
      guidelines: selectSecurityStandardGuidelines(state)(props.securityStandardId),
      guidelinesRelationships: selectSecurityStandardGuidelinesRelationships(state)(props.securityStandardId),
    }
  };
}

export default connect(SecurityStandardsSearchResultPerStandard.mapStateToProps)(SecurityStandardsSearchResultPerStandard);

