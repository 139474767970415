import React, {Component} from 'react';
import Box from '@material-ui/core/Box';
import {withStyles} from "@material-ui/core/styles";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import IconButton from "@material-ui/core/IconButton";
import type {SecurityStandardUserNote} from "amn/model/standards/SecurityStandards";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import I18n from "amn/ui/i18n/I18n";
import {I18N_NS_STANDARDS} from "amn/common/i18n/I18nextConfig";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import RichTextField from "amn/ui/forms/RichTextField";
import type {RichText} from "amn/ui/forms/RichText";
import {nanoid} from "nanoid";
import {
  deleteStandardUserNote,
  updateStandardUserNote
} from "amn/store/actions/notes/security-standard-user-notes.actions";
import {Tooltip} from "amn/ui/diverse/ToolTips";
import ConfirmButton from "amn/ui/diverse/ConfirmButton";
import {WhiteBox} from "amn/ui/containers/Boxes";

type Props = {
  note: SecurityStandardUserNote;
};

type State = {
  editDialogOpen: boolean;
  text: RichText;
};

class GuidelineUserNoteContent extends Component<Props, State> {

  constructor(props) {
    super(props);
    this.id = nanoid();
    this.state = {
      editDialogOpen: false,
      id: nanoid()
    };
  }

  openEditDialog = () => {
    this.setState({editDialogOpen: true});
  }

  closeEditDialog = () => {
    this.setState({editDialogOpen: false});
  }

  update = (event) => {
    event.preventDefault();
    updateStandardUserNote({
      ...this.props.note,
      text: this.state.text
    }).then(() => {
      this.closeEditDialog();
    })
  }

  delete = () => {
    deleteStandardUserNote(this.props.note.id);
  }

  reset = () => {
    this.setState({id: nanoid()});
  }

  onTextChange = (text) => {
    this.setState({text});
  }

  render() {
    const {editDialogOpen} = this.state;
    const {classes, note} = this.props;
    return (
      <React.Fragment>
        <div className={classes.container} onBlur={this.onBlur}>

          <RichTextField readOnly
                         defaultValue={note.text}
                         toolbar={false}/>
          <WhiteBox position="absolute"
               right={16}
               top={14}
               display="flex"
               className={classes.editButton}>
            <Box mr={1}>
              <Tooltip title={
                <I18n ns={I18N_NS_STANDARDS} i18nKey="standardNotes.edit">
                  Edit the Note
                </I18n>
              }>
                <IconButton size="small"
                            color="secondary"
                            onClick={this.openEditDialog}>
                  <EditOutlinedIcon/>
                </IconButton>
              </Tooltip>
            </Box>

            <ConfirmButton color="secondary"
                           size="small"
                           title={
                             <I18n ns={I18N_NS_STANDARDS} i18nKey="standardNotes.delete">
                               Delete the Note
                             </I18n>
                           }
                           confirmationMessage={
                             <I18n ns={I18N_NS_STANDARDS}
                                   i18nKey="standardNotes.deleteConfirmMessage">
                               Are you sure?
                             </I18n>
                           }
                           onConfirm={this.delete}>
              <Tooltip title={
                <I18n ns={I18N_NS_STANDARDS} i18nKey="standardNotes.delete">
                  Delete the Note
                </I18n>
              }>
                <DeleteOutlinedIcon/>
              </Tooltip>
            </ConfirmButton>
          </WhiteBox>


        </div>
        <Dialog open={editDialogOpen}
                onClose={this.closeEditDialog}
                fullWidth
                maxWidth={"md"}>
          <DialogTitle>
            <I18n ns={I18N_NS_STANDARDS} i18nKey="standardNotes.edit">
              Edit the Note
            </I18n>
          </DialogTitle>
          <DialogContent>
            <form key={this.state.id} onSubmit={this.update} onReset={this.reset}>
              <Box mt={1}>
                <RichTextField onTextChange={this.onTextChange}
                               autoFocus
                               inlineToolbar
                               defaultValue={note.text}
                               controls={[
                                 "title", "bold", "italic", "underline", "strikethrough", "highlight", "undo", "redo", "link", "numberList", "bulletList", "quote", "code"
                               ]}
                               label="Start typing..."/>
              </Box>

              <Box mt={3}
                   mb={1}
                   display="flex"
                   justifyContent="flex-end">
                <Box ml={1}>
                  <Button type="reset">
                    <I18n>
                      Reset
                    </I18n>
                  </Button>
                </Box>
                <Box ml={1}>
                  <Button type="submit"
                          variant="contained"
                          color="primary">
                    <I18n>
                      Update
                    </I18n>
                  </Button>
                </Box>
              </Box>

            </form>
          </DialogContent>
        </Dialog>
      </React.Fragment>
    );
  }
}

const styles = (theme) => ({

  container: {
    '&:hover $editButton': {
      display: "flex"
    },
    width: "100%",
    position: "relative",
    cursor: "pointer"
  },

  editButton: {
    display: "none"
  }
});

export default withStyles(styles)(GuidelineUserNoteContent);



