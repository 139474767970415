import produce from "immer";

import type {ListDataState, Reducer} from "../common";
import {DATA_INITIAL, DATA_LOADED, DATA_LOADING} from "../common";
import {listToLookupTable} from "amn/model/common/Divers";
import type {SecurityStandardUserNote} from "amn/model/standards/SecurityStandards";
import {
  CREATE_STANDARD_USER_NOTE_SUCCESS,
  DELETE_STANDARD_USER_NOTE_SUCCESS,
  LOAD_STANDARD_USER_NOTE_REQUEST,
  LOAD_STANDARD_USER_NOTE_SUCCESS,
  UPDATE_STANDARD_USER_NOTE_SUCCESS
} from "../../actions/notes/security-standard-user-notes.actions";

export type SecurityStandardUserNoteState = ListDataState<SecurityStandardUserNote>

const INITIAL_STATE: SecurityStandardUserNoteState = {
  entities: {},
  status: DATA_INITIAL,
}


const userNotes: Reducer = produce((draft: SecurityStandardUserNoteState, action) => {
  switch (action.type) {

    case CREATE_STANDARD_USER_NOTE_SUCCESS: {
      if (action.success) {
        let response = action.success;
        draft.entities[response.id] = {
          ...action.request.entity,
          ...response
        };
        draft.status = DATA_LOADED;
      }
      break;
    }

    case UPDATE_STANDARD_USER_NOTE_SUCCESS: {
      if (action.success) {
        let response = action.success;
        draft.entities[response.id] = {
          ...action.request.entity,
          ...response
        };
        draft.status = DATA_LOADED;
      }
      break;
    }

    ///******************************
    //
    case DELETE_STANDARD_USER_NOTE_SUCCESS: {
      const id = action.request?.id;
      delete draft.entities[id];
      draft.status = DATA_LOADED;
      break;
    }

    ///******************************
    //

    case LOAD_STANDARD_USER_NOTE_REQUEST: {
      draft.entities = {};
      draft.status = DATA_LOADING;
      break;
    }

    case LOAD_STANDARD_USER_NOTE_SUCCESS: {
      if (action.success) {
        draft.entities = listToLookupTable(action.success.items);
        draft.status = DATA_LOADED;
      }
      break;
    }


    default:
      break;
  }
}, INITIAL_STATE);


export default userNotes;
