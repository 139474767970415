import React from "react";
import ReactMarkdown from "react-markdown";
import Box from "@material-ui/core/Box";
import type {SecurityStandardGuideline} from "amn/model/standards/SecurityStandards";
import {securityStandardGuidelineReference} from "amn/model/standards/SecurityStandards";
import {I18N_NS_STANDARDS} from "amn/common/i18n/I18nextConfig";
import I18n from "amn/ui/i18n/I18n";

type Props = {
  guideline: SecurityStandardGuideline;
}

const GuidelineDocumentation = ({guideline}: Props) => {

  return (
    <React.Fragment>
      <Box p={1} onClick={(e) => e.stopPropagation()}>
        <Box fontWeight="fontWeightMedium"
             fontSize="body2.fontSize">
          {securityStandardGuidelineReference(guideline)} - {guideline?.title}
        </Box>
        {guideline?.description &&
        <Box mt={1}
             fontWeight="fontWeightLight">
          <ReactMarkdown children={guideline?.description.replaceAll("\n", "  \n")}/>
        </Box>
        }
        {!guideline?.description &&
        <Box mt={1}
             fontWeight="fontWeightLight">
          <I18n ns={I18N_NS_STANDARDS} i18nKey="standardGuidelines.fields.description.noDocumentation">
            No description available
          </I18n>
        </Box>
        }
      </Box>
    </React.Fragment>
  )
}

export default GuidelineDocumentation;
