import React, {Component} from 'react';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import type {SecurityStandard, SecurityStandardGuideline} from "amn/model/standards/SecurityStandards";
import {WhiteBox} from "amn/ui/containers/Boxes";
import ReactMarkdown from "react-markdown";
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import I18n from "amn/ui/i18n/I18n";
import BookOutlinedIcon from '@material-ui/icons/BookOutlined';
import {I18N_NS_STANDARDS} from "amn/common/i18n/I18nextConfig";
import GuidelineUserNotes from "../tree/guideline/actions/GuidelineUserNotes";
import SecurityStandardsGuidelineRelationships from "./SecurityStandardsGuidelineRelationships";
import WithBreakpoints from "../../../../ui/containers/WithBreakpoints";

type Props = {
  guideline: SecurityStandardGuideline;
  securityStandard: SecurityStandard;
};

type State = {};

class SecurityStandardGuidelineDetails extends Component<Props, State> {

  render() {
    const {guideline, securityStandard} = this.props;
    return (
      <div>
        <Box>
          <Typography variant="h5" component={"h1"} gutterBottom>
            {guideline?.title}
          </Typography>
        </Box>
        <Box overflow="auto" >
        <WithBreakpoints>
          {(bps) => (
        <WhiteBox p={4} mt={2} width={bps.smUp ? "100%" : "200%"}>

          <Box mt={2}>
            <Box display="flex"
                 alignItems="center">
              <Box display="flex"
                   mr={1}
                   color="primary.main"
                   alignItems="center">
                <BookOutlinedIcon/>
              </Box>
              <Typography variant="subtitle1"
                          color="secondary">
                Documentation
              </Typography>
            </Box>
            {guideline?.description &&
            <React.Fragment>
              <Box mt={2} fontSize="body2.fontSize">
                <ReactMarkdown children={guideline?.description.replaceAll("\n", "  \n")}/>
              </Box>
            </React.Fragment>
            }
            {!guideline?.description &&
            <Box mt={2} mb={1} color={"text.secondary"}>
              <I18n ns={I18N_NS_STANDARDS} i18nKey="standardGuidelines.fields.description.noDocumentation">
                No description available
              </I18n>
            </Box>

            }
          </Box>


          <Box mt={8}>
            <Box display="flex"
                 mb={2}
                 alignItems="center">
              <Box display="flex"
                   mr={1}
                   color="primary.main"
                   alignItems="center">
                <AccountTreeOutlinedIcon/>
              </Box>
              <Typography variant="subtitle1"
                          color="secondary">
                <I18n>
                  Hierarchy
                </I18n>
              </Typography>
            </Box>
            <SecurityStandardsGuidelineRelationships guideline={guideline} securityStandard={securityStandard}/>
          </Box>

          <Box mt={8}>
            <GuidelineUserNotes guideline={guideline}/>
          </Box>
        </WhiteBox>
          )}
        </WithBreakpoints>
        </Box>
      </div>
    );
  }
}


export default SecurityStandardGuidelineDetails;



