import type {RichText} from "amn/ui/forms/RichText";
import {TYPE_DISCRIMINATOR} from "amn/model/common/Divers";
import get from "lodash/get";
import filter from "lodash/filter";
import memoize from "lodash/memoize";
import find from "lodash/find";
import first from "lodash/first";
import SecurityStandardGuidelineTypeOptions from "./SecurityStandardGuidelineTypeOptions.json";
import {getOptionByValue} from "../../ui/forms/GenericOption";
import type {GenericOption} from "../../ui/forms/GenericOption";
import includes from "lodash/includes";
import type {PersistentEntity} from "../common/Entity";
import {I18nText} from "../../ui/i18n/i18nText.types";


export const SECURITY_STANDARD_GUIDELINE_REFERENCE_ORIGIN_CUSTOM = "CUSTOM";
export const SECURITY_STANDARD_GUIDELINE_REFERENCE_ORIGIN_STANDARD = "STANDARD";

export type SecurityStandardGuidelineReferenceOrigin =
  SECURITY_STANDARD_GUIDELINE_REFERENCE_ORIGIN_STANDARD |
  SECURITY_STANDARD_GUIDELINE_REFERENCE_ORIGIN_CUSTOM;

export type SecurityStandardGuidelineReference = {
  reference: string;
  origin: SecurityStandardGuidelineReferenceOrigin;
}


export const SECURITY_STANDARD_GUIDELINE_WITH_SUB_GUIDELINES = "SecurityStandardGuidelineWithSubGuidelines";
export const SECURITY_STANDARD_LEAF_GUIDELINE = "SecurityStandardLeafGuideline";

export const isGuidelineWithSubGuidelines = (guideline: SecurityStandardGuideline) => {
  return guideline && guideline[TYPE_DISCRIMINATOR] === SECURITY_STANDARD_GUIDELINE_WITH_SUB_GUIDELINES;
}

export const isLeafGuideline = (guideline: SecurityStandardGuideline) => {
  return guideline && guideline[TYPE_DISCRIMINATOR] === SECURITY_STANDARD_LEAF_GUIDELINE;
}

type SecurityStandardGuidelineBase = {
  id: string;
  reference: SecurityStandardGuidelineReference;
  title: string;
  description?: string;
  relatedVariants: string[];
  tags: Array<SecurityStandardGuidelineType>;
}


export type SecurityStandardLeafGuideline = {
  [TYPE_DISCRIMINATOR]: SECURITY_STANDARD_LEAF_GUIDELINE;
} & SecurityStandardGuidelineBase;


export type SecurityStandardGuidelineWithSubGuidelines = {
  [TYPE_DISCRIMINATOR]: SECURITY_STANDARD_GUIDELINE_WITH_SUB_GUIDELINES;
  subGuidelines: string[];
} & SecurityStandardGuidelineBase;


export type SecurityStandardGuideline =
  SecurityStandardLeafGuideline |
  SecurityStandardGuidelineWithSubGuidelines;


export type SecurityStandardGuidelineRelationships = {
  guidelineId: string;
  ancestorsIds: string[];
  descendentsIds: string[];
  directParentsIds: string[];
  directChildrenIds: string[];
}

export type SecurityStandardVariant = {
  id: string;
  name: string;
  description?: string;
}

export type SecurityStandard = {
  id: string;
  name: string;
  description?: string;
  variants: SecurityStandardVariant[];
}


export const securityStandardGuidelineReference = (guideline: SecurityStandardGuideline): string => {
  return get(guideline, "reference.reference", "");
}


export const getSecurityStandardRootGuidelines = (guidelines: Array<SecurityStandardGuideline>): Array<SecurityStandardGuideline> => {
  return filter(guidelines, guideline => {
    return !find(guidelines,
      (otherGuideline: SecurityStandardGuideline) => isGuidelineWithSubGuidelines(otherGuideline) && includes(otherGuideline.subGuidelines, guideline.id))
  });
}

export const SECURITY_STANDARD_GUIDELINE_TYPE_WITH_STATUS = "WITH_STATUS";
export const SECURITY_STANDARD_GUIDELINE_TYPE_SECTION = "SECTION";
export const SECURITY_STANDARD_GUIDELINE_TYPE_CHECK_POINT = "CHECK_POINT";
export type SecurityStandardGuidelineType =
  SECURITY_STANDARD_GUIDELINE_TYPE_WITH_STATUS
  | SECURITY_STANDARD_GUIDELINE_TYPE_SECTION
  | SECURITY_STANDARD_GUIDELINE_TYPE_CHECK_POINT;

export type SecurityStandardGuidelineTypeOption = GenericOption<SecurityStandardGuidelineType>;
export const SECURITY_STANDARD_GUIDELINE_TYPE_OPTIONS: Array<SecurityStandardGuidelineTypeOption> = SecurityStandardGuidelineTypeOptions;

export const getSecurityStandardGuidelineTypeOption = memoize((v: SecurityStandardGuidelineType): SecurityStandardGuidelineTypeOption => {
  return getOptionByValue(v, SecurityStandardGuidelineTypeOptions);
});

export const isGuidelineWithStatus = (guideline: SecurityStandardGuideline) => {
  return guideline && includes(guideline.tags, SECURITY_STANDARD_GUIDELINE_TYPE_WITH_STATUS);
}

export const isGuidelineCheckPoint = (guideline: SecurityStandardGuideline) => {
  return guideline && includes(guideline.tags, SECURITY_STANDARD_GUIDELINE_TYPE_CHECK_POINT);
}

export const getGuidelineTypeDescription = (guideline: SecurityStandardGuideline): I18nText => {
  if (guideline) {
    const typeOption = getSecurityStandardGuidelineTypeOption(first(guideline.tags));
    return typeOption?.description;
  }
}


export type SecurityStandardUserNote = {
  standardId: string;
  guidelineId: string;
  userId: string;
  visibility: {
    type: string;
  };
  text: RichText;
} & PersistentEntity;
