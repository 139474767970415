import AVAILABLE_STANDARDS from "./available-standards.json";
import type {SecurityStandard, SecurityStandardGuideline} from "amn/model/standards/SecurityStandards";
import FlexSearch from "flexsearch";
import forEach from "lodash/forEach";
import map from "lodash/map";
import reverse from "lodash/reverse";


export type SecurityStandardData = SecurityStandard & {
  guidelines: SecurityStandardGuideline[];
}




export const getAvailableStandards = (): SecurityStandard[] => {
  return AVAILABLE_STANDARDS;
}

export const loadStandardData = (id: string): Promise<SecurityStandardData> => {
  return new Promise((resolve, reject) => {
    import("./_" + id + ".json")
      .then(resolve)
      .catch(reject);
  })
}

export const loadStandardGuidelines = (id: string): Promise<SecurityStandardGuideline[]> => {
  return loadStandardData(id).then(standard => {
    return standard.guidelines;
  })
}

let GUIDELINES_INDEX;

export const getSecurityStandardsIndex = (): Promise<FlexSearch> => {
  if (GUIDELINES_INDEX) {
    return Promise.resolve(GUIDELINES_INDEX);
  }
  GUIDELINES_INDEX = new FlexSearch({
    cache: true,
    doc: {
      id: "id",
      field: {
        "reference:reference": {
          split: "\\s+"
        },
        title: {
          encode: "advanced",
        }
      },
      store: ["id", "reference", "title", "standard"]
    }
  });


  const promises = map(AVAILABLE_STANDARDS, (standard) => new Promise((resolve, reject) => {
    loadStandardGuidelines(standard.id)
      .then((guidelines: SecurityStandardGuideline[]) => {
        forEach(guidelines, guideline => {
          const datum = {...guideline, standard: {id: standard.id, name: standard.name}};
          GUIDELINES_INDEX.add(datum);
        });
        resolve();
      })
      .catch(reject);
  }));

  // on utilise reverse pour contrôle un petit peu l'ordre d'exécution des promises, pour que les premiers
  // Référentiels de la liste soit indexés en premier...
  return Promise.all(reverse(promises)).then(() => GUIDELINES_INDEX);
}
