import React from 'react';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Link from '@material-ui/core/Link';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import YouTubeIcon from '@material-ui/icons/YouTube';
import CallOutlinedIcon from '@material-ui/icons/CallOutlined';
import EmailOutlinedIcon from '@material-ui/icons/EmailOutlined';
import {I18N_NS_BRANDING} from "amn/common/i18n/I18nextConfig";
import I18n from "amn/ui/i18n/I18n";


export default function PrimaryFooter() {
  return (
    <footer>
      <Box bgcolor="secondary.main"
           color="secondary.contrastText"
           pt={8}
           pb={9}>
        <Container maxWidth="lg">

          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <Link color='inherit' href="https://amnbrains.com" target="_blank">
                <Box width={170} height={170}>
                  <img src={require("assets/images/logo/amn-brains-w.png")}
                       style={{maxWidth: '100%', maxHeight: '100%'}}
                       alt="Amn Brains"/>
                </Box>
              </Link>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant="subtitle1">
                <I18n ns={I18N_NS_BRANDING} i18nKey="companyName">
                  Amn Brains
                </I18n>
              </Typography>
              <Box mt={2}>
                <Link color='inherit' href="https://amnbrains.com/" target="_blank">
                  <I18n ns={I18N_NS_BRANDING} i18nKey="about">
                    About
                  </I18n>
                </Link>
              </Box>
              <Box mt={1} mb={2}>
                <Link color='inherit' href="https://amnbrains.com/privacy-policy" target="_blank">
                  <I18n ns={I18N_NS_BRANDING} i18nKey="privacy">
                    Privacy Policy
                  </I18n>
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant="subtitle1">
                Solutions
              </Typography>
              <Box mt={2}>
                <Link color='inherit' href="https://amnbrains.com/amn-robot" target="_blank">
                  Amn Robot
                </Link>
              </Box>
              <Box mt={1}>
                <Link color='inherit' href="https://amnbrains.com/amn-auditor" target="_blank">
                  Amn Auditor
                </Link>
              </Box>
              <Box mt={1} mb={2}>
                <Link color='inherit' href="https://amnbrains.com/amn-standards" target="_blank">
                  Amn Standards
                </Link>
              </Box>
            </Grid>

            <Grid item xs={12} sm={2}>
              <Typography variant="subtitle1">
                <I18n ns={I18N_NS_BRANDING} i18nKey="contact">
                  Contact Us
                </I18n>
              </Typography>
              <Box mt={2}>
                Digital Square, 1137 Avenue des Champs Blancs <br/> 35510 Cesson-Sévigné
              </Box>
              <Box mt={2}
                   display={"flex"}
                   alignItems={"center"}>
                <Box mr={1}
                     display={"flex"}
                     alignItems={"center"}>
                  <CallOutlinedIcon fontSize="small"/>
                </Box>

                <Link color='inherit' href="tel:+33259010003">
                  +33 (0) 2 59 01 00 03
                </Link>
              </Box>
              <Box mt={2}
                   mb={2}
                   display={"flex"}
                   alignItems={"center"}>
                <Box mr={1}
                     display={"flex"}
                     alignItems={"center"}>
                  <EmailOutlinedIcon fontSize="small"/>
                </Box>


                <Link color='inherit' href="mailto:contact@amnbrains.com">
                  contact@amnbrains.com
                </Link>
              </Box>
            </Grid>
            <Grid item xs={12} sm={2}>
              <Typography variant="subtitle1">
                <I18n ns={I18N_NS_BRANDING} i18nKey="follow">
                  Follow Us
                </I18n>
              </Typography>
              <Box mt={2} display="flex">
                <Box mr={2}>
                  <Link color='inherit' href="https://twitter.com/Amn_Brains" target="_blank">
                    <TwitterIcon fontSize="small"/>
                  </Link>
                </Box>
                <Box mr={2}>
                  <Link color='inherit' href="https://www.youtube.com/channel/UC66Nj4oYRt957RqbWJ_g_2w" target="_blank">
                    <YouTubeIcon fontSize="small"/>
                  </Link>
                </Box>
                <Box mr={2}>
                  <Link color='inherit' href="https://www.linkedin.com/company/amnbrains" target="_blank">
                    <LinkedInIcon fontSize="small"/>
                  </Link>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>

      </Box>
      <Box display={"flex"}
           justifyContent={"center"}
           bgcolor="secondary.main"
           p={1}
           color="secondary.contrastText">
        <Typography variant={"caption"}>
          <I18n ns={I18N_NS_BRANDING} i18nKey="copyright">
            © Copyright 2021 - All Rights Reserved - Amn Brains.
          </I18n>
        </Typography>
      </Box>
    </footer>
  );


}






