import React, {Component} from 'react';
import map from "lodash/map";
import {connect} from "react-redux";
import type {LookupTable} from "amn/model/common/Divers";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import type {DataStatus} from "amn/store/reducers/common";
import {DATA_LOADED, DATA_LOADING} from "amn/store/reducers/common";
import {
  selectSecurityStandardGuidelinesDataStatus,
  selectSecurityStandardRootGuidelines
} from "amn/store/selectors/standards/security-standards.selectors";
import type {SecurityStandard, SecurityStandardGuideline} from "amn/model/standards/SecurityStandards";
import GuidelineTreeItem from "./guideline/GuidelineTreeItem";

type Props = {
  securityStandard: SecurityStandard;
  rootGuidelines: LookupTable<SecurityStandardGuideline>;
  dataStatus: DataStatus;
};

type State = {};

class SecurityStandardGuidelinesTree extends Component<Props, State> {

  render() {
    const {rootGuidelines, dataStatus} = this.props;
    return (
      <React.Fragment>

        {dataStatus === DATA_LOADED &&

        <React.Fragment>
          {
            map(rootGuidelines, (guideline: SecurityStandardGuideline) => (
              <Box key={guideline.id}>
                <GuidelineTreeItem guideline={guideline}/>
              </Box>
            ))
          }
        </React.Fragment>
        }

        {dataStatus === DATA_LOADING &&
        <Box display={"flex"} justifyContent="center">
          <CircularProgress/>
        </Box>
        }


      </React.Fragment>
    );
  }

  static mapStateToProps = (state, props) => {
    const {securityStandard} = props;
    return {
      dataStatus: selectSecurityStandardGuidelinesDataStatus(state)(securityStandard?.id),
      rootGuidelines: selectSecurityStandardRootGuidelines(state)(securityStandard?.id),
    }
  };
}


export default connect(SecurityStandardGuidelinesTree.mapStateToProps)(SecurityStandardGuidelinesTree);

