import {
  selectStandardsUserNotes,
  selectStandardsUserNotesDataStatus
} from "../../selectors/notes/security-standards-user-notes.selectors";
import {DATA_LOADED} from "../../reducers/common";
import store from "amn/store";
import {loadStandardUserNotes} from "../../actions/notes/security-standard-user-notes.actions";
import firebaseAuth from "amn/firebase/auth/FirebaseAuth";


const SecurityStandardsUserNotesManager = {

  init: () => {
    SecurityStandardsUserNotesManager.loadUserNotes();
    firebaseAuth.setAuthStateListener((user) => {
      if (user) {
        SecurityStandardsUserNotesManager.loadUserNotes();
      }
    });
  },

  loadUserNotes: () => {
    const dataStatus = selectStandardsUserNotesDataStatus(store.getState());
    if (dataStatus !== DATA_LOADED && firebaseAuth.getCurrentUser()) {
      return loadStandardUserNotes(firebaseAuth.getCurrentUserId());
    } else {
      const items = selectStandardsUserNotes(store.getState());
      return Promise.resolve({success: {items: Object.values(items)}});
    }
  },

}


export default SecurityStandardsUserNotesManager;
