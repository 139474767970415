import React from 'react';
import {Trans, TransProps, useTranslation} from "react-i18next";


function I18n(props: TransProps) {
  const {t} = useTranslation(props.ns);

  return <Trans t={t} {...props}/>;
}

export default I18n;



