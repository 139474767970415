import omit from "lodash/omit";
import type {SecurityStandardUserNote} from "amn/model/standards/SecurityStandards";
import type {CreationResponse, ListResponse, UpdateResponse} from "amn/model/common/Responses";
import firebase from "amn/firebase";

const USER_NOTES_COLLECTION_NAME = "securityStandardUserNotes";

export default {

  loadUserNotes: (userId: string): Promise<ListResponse<SecurityStandardUserNote>> => {
    return new Promise((resolve, reject) => {
      firebase.firestore().collection(USER_NOTES_COLLECTION_NAME)
        .where("userId", "==", userId)
        .get()
        .then((snapshot) => {
          const items = [];
          snapshot.forEach((doc) => {
            const item = {id: doc.id, ...doc.data()};
            items.push(item);
          });
          resolve({items});
        })
        .catch(reject);
    })

  },

  createUserNote: (note: Partial<SecurityStandardUserNote> = {}): Promise<CreationResponse> => {
    return new Promise((resolve, reject) => {
      firebase.firestore().collection(USER_NOTES_COLLECTION_NAME)
        .add({
          ...note,
          creationDate: firebase.firestore.FieldValue.serverTimestamp(),
          lastModificationDate: firebase.firestore.FieldValue.serverTimestamp(),
        })
        .then((docRef) => {
          resolve({
            id: docRef.id,
          })
        })
        .catch(reject);
    });
  },

  updateUserNote: (note: SecurityStandardUserNote = {}): Promise<UpdateResponse> => {
    const docRef = firebase.firestore().collection(USER_NOTES_COLLECTION_NAME).doc(note.id);
    return new Promise((resolve, reject) => {
      firebase.firestore()
        .runTransaction((transaction) => {
          return transaction.get(docRef)
            .then((doc) => {
              if (!doc.exists) {
                reject("Document does not exist!");
              }
              transaction.set(docRef, {
                ...omit(note, "id"),
                lastModificationDate: firebase.firestore.FieldValue.serverTimestamp()
              });
            });
        })
        .then(() => {
          resolve({id: note.id})
        })
        .catch(reject);
    });
  },


  deleteUserNote: (id: string): Promise<void> => {
    return new Promise((resolve, reject) => {
      firebase.firestore().collection(USER_NOTES_COLLECTION_NAME)
        .doc(id)
        .delete()
        .then(() => {
          resolve()
        })
        .catch(reject);
    });
  },

}
