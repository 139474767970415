import store from "amn/store";
import type {ActionPromise} from "../common";
import type {SecurityStandardGuideline} from "amn/model/standards/SecurityStandards";
import type {ListResponse} from "amn/model/common/Responses";
import SecurityStandardsBackendAdapter from "amn/backend/adapters/SecurityStandardsBackendAdapter";


export const LOAD_STANDARD_GUIDELINES_REQUEST = 'app/standards/LOAD_STANDARD_GUIDELINES_REQUEST';
export const LOAD_STANDARD_GUIDELINES_SUCCESS = 'app/standards/LOAD_STANDARD_GUIDELINES_SUCCESS';
export const LOAD_STANDARD_GUIDELINES_ERROR = 'app/standards/LOAD_STANDARD_GUIDELINES_ERROR';


export function loadStandardGuidelines(securityStandardId: string): ActionPromise<{ securityStandardId: string }, ListResponse<SecurityStandardGuideline>> {
  return store.dispatch({
    types: [LOAD_STANDARD_GUIDELINES_REQUEST, LOAD_STANDARD_GUIDELINES_SUCCESS, LOAD_STANDARD_GUIDELINES_ERROR],
    securityStandardId,
    promise: SecurityStandardsBackendAdapter.loadSecurityStandardGuidelines(securityStandardId)
  });
}
