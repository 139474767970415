import React, {Component} from 'react';
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Box from "@material-ui/core/Box";
import PrimaryAppBarSearchControl from "./PrimaryAppBarSearchControl";
import withStyles from "@material-ui/core/styles/withStyles";
import I18n from "amn/ui/i18n/I18n";
import Button from "@material-ui/core/Button";
import {I18N_NS_BRANDING} from "amn/common/i18n/I18nextConfig";
import ElevationScroll from "./ElevationScroll";
import {NavLink} from "react-router-dom";
import Link from "@material-ui/core/Link";
import Hidden from "@material-ui/core/Hidden";
import ButtonSignIn from "./ButtonSignIn";
import DrawerAppBar from "./DrawerAppBar";
type Props = {};
type State = {};
class PrimaryAppBar extends Component<Props, State> {


  render() {
    const {classes} = this.props;
    return (
      <Box flexGrow={1}>

        <ElevationScroll>
          {(trigger) => (
            <AppBar position="fixed"
                    elevation={trigger ? 3 : 0}
                    className={classes.appBar}>
              <Toolbar>
                <Box>
                  <Link color="inherit" component={NavLink}
                        to={"/"}>
                    <img src={require("assets/images/logo/amn-standards.png")}
                         alt={"Amn Standards Logo"}
                         className={classes.logo}/>
                  </Link>
                </Box>
                <Box mr={2} ml={2} flex={1}>
                  <PrimaryAppBarSearchControl/>
                </Box>

                <Hidden smDown>
                  <Button className={classes.button}
                          color={"secondary"}
                          href={"https://amnbrains.com/"}
                          target="_blank">
                    <I18n ns={I18N_NS_BRANDING} i18nKey="companyName">
                      Amn Brains
                    </I18n>
                  </Button>
                  <Button className={classes.button}
                          color={"secondary"}
                          href={"https://amnbrains.com/#solutions"}
                          target="_blank">
                    <I18n ns={I18N_NS_BRANDING} i18nKey="solutions">
                      Solutions
                    </I18n>
                  </Button>
                  <Button className={classes.button}
                          color={"secondary"}
                          href={"https://amnbrains.com/blog"}
                          target="_blank">
                    <I18n ns={I18N_NS_BRANDING} i18nKey="blog">
                      Blog
                    </I18n>
                  </Button>
                  <ButtonSignIn/>
                </Hidden>
                <Hidden mdUp>
                  <DrawerAppBar/>
                </Hidden>
              </Toolbar>
            </AppBar>
          )}
        </ElevationScroll>
      </Box>
    );
  }

}


const styles = (theme) => ({

  appBar: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.text.primary,
  },
  logo: {
    height: "56px",
    margin: "4px"
  },
  button: {
    // color : theme.palette.primary.contrastText
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  }
})

export default withStyles(styles)(PrimaryAppBar);



