import React, {Component} from 'react';
import {CollapseProps} from "@material-ui/core/Collapse/Collapse";
import Collapse from "@material-ui/core/Collapse";

type Props = CollapseProps;

type State = {};

/**
 * A Collapse that mounts its children only at its first opening. (if closed initially, no children are mounted)
 */
class LazyCollapse extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.openedAtLeastOnce = props.in || false;
  }

  render() {
    const {in: open, children} = this.props;
    this.openedAtLeastOnce = this.openedAtLeastOnce || open;
    return (
      <Collapse {...this.props}>
        {
          this.openedAtLeastOnce ? children : (open && children)
        }
      </Collapse>
    );
  }
}


export default LazyCollapse;



