import React, {Component} from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import LoginForm from "./LoginForm";
import {connect} from "react-redux";
import {isLoginDialogOpen, selectLoginReason} from "amn/store/selectors/iam/iam-ui.selectors";
import {closeLoginDialog} from "amn/store/actions/iam/iam-ui.actions";

type Props = {
  open: boolean;
  reason: string;
};

type State = {};

class LoginDialog extends Component<Props, State> {

  close = () => {
    closeLoginDialog();
  }

  render() {
    return (
      <Dialog maxWidth={"md"} open={this.props.open} onClose={this.close}>

        <DialogContent>
          <LoginForm onLoginSuccess={this.close}
                     reason={this.props.reason}/>
        </DialogContent>
      </Dialog>
    );
  }

  static mapStateToProps = (state) => {
    return {
      open: isLoginDialogOpen(state),
      reason: selectLoginReason(state)
    }
  };
}


export default connect(LoginDialog.mapStateToProps)(LoginDialog);
