import React, {Component} from 'react';

import firebase from "amn/firebase";
import {Provider} from 'react-redux';
import {SnackbarProvider} from 'notistack';
import Config from "amn/Config";
import {BrowserRouter as Router} from 'react-router-dom';

import store from 'amn/store';
import ThemeProvider from 'amn/modules/theme/ThemeProvider';
import {FirebaseAuthProvider} from '@react-firebase/auth';
import Root from "./Root";
import SecurityStandardsUserNotesManager from "./store/actors/notes/SecurityStandardsUserNotesManager";


type Props = {
  i18n?: Object;
}


export default class App extends Component<Props> {

  componentDidMount() {
    if (window.location.hostname === "localhost" || window.location.hostname === "m.local") {
      firebase.auth().useEmulator('http://localhost:9099/');
      firebase.firestore().useEmulator("localhost", 8080);
    }

    SecurityStandardsUserNotesManager.init();
  }


  render() {
    return (
      <Provider store={store}>
        <ThemeProvider>
          <Router>
            <div>
              <FirebaseAuthProvider firebase={firebase} {...Config.FIREBASE_CONFIG}>
                <SnackbarProvider>
                  <Root/>
                </SnackbarProvider>
              </FirebaseAuthProvider>
            </div>
          </Router>
        </ThemeProvider>
      </Provider>
    );
  }
}
