import React, {Component} from 'react';
import map from "lodash/map";
import size from "lodash/size";
import {connect} from "react-redux";
import TreeItem from "amn/ui/containers/TreeItem";
import Box from "@material-ui/core/Box";
import type {LookupTable} from "amn/model/common/Divers";
import type {SecurityStandardGuideline} from "amn/model/standards/SecurityStandards";
import {isLeafGuideline} from "amn/model/standards/SecurityStandards";
import {SecurityStandardContext} from "../SecurityStandardContexts";
import LazyCollapse from "amn/ui/containers/LazyCollapse";
import Divider from "@material-ui/core/Divider";
import {BackBox} from "amn/ui/containers/Boxes";
import GuidelineDetails from "./GuidelineDetails";
import {selectAllSecurityStandardsGuidelines} from "amn/store/selectors/standards/security-standards.selectors";
import GuidelineTreeItemHeader from "./GuidelineTreeItemHeader";
import LazilyRender from "react-lazily-render";

type Props = {
  guideline: SecurityStandardGuideline;
  guidelines: LookupTable<SecurityStandardGuideline>;
};


type State = {
  showDetails: boolean;
};

class InnerSecurityStandardGuidelineTreeItem extends Component<Props, State> {

  static contextType = SecurityStandardContext;

  state = {
    showDetails: false,
    open: false
  }

  toggleDetails = (event) => {
    event.stopPropagation();
    this.setState({showDetails: !this.state.showDetails});
  }

  toggle = () => {
    if (!this.state.showDetails) {
      const {onGuidelineOpenStateChange} = this.context;
      const {guideline} = this.props;
      onGuidelineOpenStateChange(guideline.id, !this.isOpen())
    }
    this.setState({showDetails: false});
  }

  isOpen = () => {
    const viewState = this.getViewState();
    return viewState?.open;
  }

  isVisible = () => {
    const viewState = this.getViewState();
    return viewState?.visible;
  }

  isHighlighted = () => {
    const viewState = this.getViewState();
    return viewState?.highlighted;
  }

  getViewState = () => {
    const {guideline} = this.props;
    const {getGuidelineViewState} = this.context;
    return getGuidelineViewState(guideline.id);
  }

  render() {
    const {guideline} = this.props;
    const open = this.isOpen();
    const visible = this.isVisible();
    return (
      <Box>
        {visible &&
        <TreeItem label={this.renderHeader()}
                  open={open}
                  onToggle={this.toggle}
                  secondaryLabel={this.renderDetails()}
                  hideFoldButton={isLeafGuideline(guideline)}>
          {this.renderSubGuidelines()}
        </TreeItem>

        }
      </Box>
    );
  }


  renderHeader = () => {
    const {guideline} = this.props;
    const {showDetails} = this.state;
    return <GuidelineTreeItemHeader guideline={guideline}
                                    highlight={this.isHighlighted()}
                                    showDetails={showDetails}
                                    onToggleDetails={this.toggleDetails}/>
  }

  renderSubGuidelines = () => {
    const {guideline, guidelines} = this.props;
    if (guideline && size(guideline.subGuidelines) > 0) {
      return (
        <Box mt={1}>

          <Box>
            {
              map(guideline.subGuidelines, (subGuidelineId: string) => {
                const subGuideline = guidelines[subGuidelineId];
                return (
                  <Box key={subGuidelineId}>
                    <LazilyRender>
                      {render => render
                        ? (
                          <SecurityStandardGuidelineTreeItem guideline={subGuideline}/>
                        )
                        : (
                          <Box display="flex" justifyContent="center" alignItems="center">
                            Scroll to show...
                          </Box>
                        )
                      }
                    </LazilyRender>

                  </Box>)
              })
            }
          </Box>

        </Box>
      );
    }
  }

  renderDetails = () => {
    const {guideline} = this.props;
    const {showDetails} = this.state;
    return (
      <LazyCollapse in={showDetails} timeout="auto">
        <React.Fragment>
          <Divider/>
          <BackBox p={2}>
            <GuidelineDetails guideline={guideline}/>
          </BackBox>
        </React.Fragment>
      </LazyCollapse>
    )
  }


  static mapStateToProps = (state, props,) => {
    return {
      guidelines: selectAllSecurityStandardsGuidelines(state)
    }
  };
}


const SecurityStandardGuidelineTreeItem = connect(InnerSecurityStandardGuidelineTreeItem.mapStateToProps)(InnerSecurityStandardGuidelineTreeItem);
export default SecurityStandardGuidelineTreeItem;



