import React, {Component, ReactNode} from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import I18n from "amn/ui/i18n/I18n";
import size from "lodash/size";
import map from "lodash/map";
import HandClickIcon from "amn/ui/icons/HandClickIcon";
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import {I18N_NS_STANDARDS} from "amn/common/i18n/I18nextConfig";
import {stopPropagation} from "amn/ui/containers/Boxes";
import Box from "@material-ui/core/Box";
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import type {SecurityStandardGuideline, SecurityStandardUserNote} from "amn/model/standards/SecurityStandards";
import ButtonBase from "@material-ui/core/ButtonBase";
import AssignmentAddIcon from "amn/ui/icons/AssignmentAddIcon";
import DialogTitle from "@material-ui/core/DialogTitle";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import GuidelineNoteForm from "./GuidelineNoteForm";
import AuthChecker from "amn/store/actors/iam/AuthChecker";
import {selectGuidelineNotes} from "amn/store/selectors/notes/security-standards-user-notes.selectors";
import type {LookupTable} from "amn/model/common/Divers";
import Badge from "@material-ui/core/Badge";
import isEmpty from "lodash/isEmpty";
import RichTextValue from "amn/ui/forms/RichTextValue";
import {MediumTooltip} from "amn/ui/diverse/ToolTips";
import {connect} from "react-redux";

type Props = {
  guideline: SecurityStandardGuideline;
  renderButton?: (onClick)=> ReactNode;
  notes: LookupTable<SecurityStandardUserNote>;
} & WithTranslation;

type State = {
  creationDialogOpen: boolean;
};


class GuidelineAddNoteButton extends Component<Props, State> {

  state = {
    creationDialogOpen: false
  }

  openCreationDialog = () => {
    AuthChecker.doIfAuthed(() => {
      this.setState({creationDialogOpen: true});
    }, this.props.t("standardNotes.mustLogin"));
  }

  closeCreationDialog = () => {
    this.setState({creationDialogOpen: false});
  }

  render() {
    const {guideline} = this.props;
    const {creationDialogOpen} = this.state;
    return (
      <Box onClick={stopPropagation}>

        {this.renderButton()}
        <Dialog open={creationDialogOpen}
                onClose={this.closeCreationDialog}
                fullWidth
                maxWidth={"md"}>
          <DialogTitle>
            <I18n ns={I18N_NS_STANDARDS} i18nKey="standardNotes.add">
              Add a Note
            </I18n>
          </DialogTitle>
          <DialogContent>
            <GuidelineNoteForm
              onCancel={this.closeCreationDialog}
              onSuccess={this.closeCreationDialog}
              guideline={guideline}/>
          </DialogContent>
        </Dialog>
      </Box>
    );
  }

  renderButton = () => {
    const {renderButton} = this.props;

    if (renderButton) {
      return renderButton(this.openCreationDialog);
    } else {
      const {notes} = this.props;
      return (
        <MediumTooltip title={this.getTooltipContent()} interactive={!isEmpty(notes)}>
          <ButtonBase onClick={this.openCreationDialog}>
            <Badge badgeContent={size(notes)} color="primary" variant={"dot"}>
              <AssignmentAddIcon color="secondary"/>
            </Badge>

          </ButtonBase>
        </MediumTooltip>
      )
    }
  }

  getTooltipContent = () => {
    const {notes} = this.props;
    return (
      <Box p={2}>

        <Box display="flex"
             mb={1}
             alignItems='center'>
          <HandClickIcon fontSize="small"/>
          <Box ml={1}>
            <I18n ns={I18N_NS_STANDARDS}
                  i18nKey={"standardGuidelines.fields.notes.clickToAdd"}>
              Click to add a new note
            </I18n>
          </Box>
        </Box>
        <Box display="flex"
             alignItems='center'>
          <HelpOutlineOutlinedIcon fontSize="small"/>
          <Box ml={1}>
            <I18n ns={I18N_NS_STANDARDS}
                  i18nKey={"standardGuidelines.fields.notes.fieldDescription"}>
              You can create notes about standards guidelines. Using notes, you can for example create comments, add
              missing or complementary information, or even add directives about how to audit the corresponding standard
              guideline.
            </I18n>
          </Box>
        </Box>

        {!isEmpty(notes) &&
        <React.Fragment>

          <Box display="flex"
               mt={3}
               fontWeight={"fontWeightMedium"}
               alignItems='center'>
            <AssignmentOutlinedIcon fontSize="small"/>
            <Box ml={1}>
              <I18n ns={I18N_NS_STANDARDS} i18nKey="standardNotes.yours">
                Your Notes
              </I18n>
            </Box>
          </Box>

          <Box mt={1}
               fontWeight={"fontWeightRegular"}>

            {map(notes, (note) => (
              <Box key={note.id}
                   borderLeft={2}
                   pl={1}
                   mt={1}
                   mb={1}>
                <RichTextValue value={note.text}/>
              </Box>
            ))}
          </Box>
        </React.Fragment>
        }

      </Box>
    );
  }


  static mapStateToProps = (state, props) => {
    const {
      guideline
    }

      = props;
    return {
      notes: selectGuidelineNotes(state)(guideline?.id)
    }
  };
}


export default connect(GuidelineAddNoteButton.mapStateToProps)(withTranslation(I18N_NS_STANDARDS)(GuidelineAddNoteButton))



