import store from "amn/store";


export const OPEN_LOGIN_DIALOG = 'app/iam/OPEN_LOGIN_DIALOG';
export const CLOSE_LOGIN_DIALOG = 'app/iam/CLOSE_LOGIN_DIALOG';


export function openLoginDialog(reason ="") {
  store.dispatch({
    type: OPEN_LOGIN_DIALOG,
    reason
  });
}

export function closeLoginDialog() {
  store.dispatch({
    type: CLOSE_LOGIN_DIALOG
  });
}
