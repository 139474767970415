import React, {Component} from 'react';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {NavLink} from "react-router-dom";
import {Helmet} from "react-helmet";
import SecurityStandardDetailsViewer from "../components/standard/SecurityStandardDetailsViewer";
import {
  selectSecurityStandards,
  selectSecurityStandardsDataStatus
} from "amn/store/selectors/standards/security-standards.selectors";
import {connect} from "react-redux";
import AccountBalanceOutlinedIcon from '@material-ui/icons/AccountBalanceOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import Container from "@material-ui/core/Container";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Link from "@material-ui/core/Link";
import I18n from "amn/ui/i18n/I18n";
import {I18N_NS_STANDARDS} from "amn/common/i18n/I18nextConfig";
import {SECURITY_STANDARDS_BASE_URL} from "../../common/routes/standards-routes";

class SecurityStandardDetailsScreen extends Component {


  render() {
    const {securityStandard} = this.props;
    return (
      <Container maxWidth={"lg"}>
        <Helmet titleTemplate="Amn Standards | %s">
          <title lang="fr">{securityStandard?.name}, Liste des exigences du référentiel</title>
          <meta name="description"
                content={`Liste des Exigences du Référentiel ${securityStandard?.name}. ${securityStandard?.description}`}/>
          <meta name="keywords"
                content={`Référentiel sécurité, Référentiel cybersécurité, ${securityStandard?.name}, ${securityStandard?.description}`}/>
        </Helmet>
        <Box mb={2}>
          <Box mb={2}>
            <Breadcrumbs separator="›">
              <Link color="inherit" component={NavLink}
                    to={SECURITY_STANDARDS_BASE_URL}>
                <Box display="flex"
                     fontSize={"body2.fontSize"}
                     alignItems="center">
                  <Box mr={1}
                       display="flex"
                       alignItems="center">
                    <HomeOutlinedIcon fontSize="small"/>
                  </Box>
                  <I18n ns={I18N_NS_STANDARDS} i18nKey="standards.all">
                    Security Standards
                  </I18n>
                </Box>
              </Link>

              <Box display="flex"
                   color="primary.main"
                   fontSize={"body2.fontSize"}
                   alignItems="center">
                <Box mr={1}
                     display="flex"
                     alignItems="center">
                  <AccountBalanceOutlinedIcon fontSize="small"/>
                </Box>
                {securityStandard?.name}
              </Box>
            </Breadcrumbs>
          </Box>
          <Box>
            <Typography variant="h5" component={"h1"} gutterBottom>
              {securityStandard?.name}
            </Typography>
          </Box>
          <div style={{wordWrap: "break-word"}}>
            {securityStandard?.description &&
            <Typography color="textSecondary"
                        variant={"body2"}>
              {securityStandard?.description}
            </Typography>
            }
          </div>
        </Box>

        {securityStandard &&
        <SecurityStandardDetailsViewer securityStandard={securityStandard} key={securityStandard.id}/>
        }

      </Container>
    );
  }

  static mapStateToProps = (state, props) => {
    const {match} = props;
    const {params} = match;
    const securityStandards = selectSecurityStandards(state);
    return {
      dataStatus: selectSecurityStandardsDataStatus(state),
      securityStandard: securityStandards[params.id]
    }
  };
}


export default connect(SecurityStandardDetailsScreen.mapStateToProps)(SecurityStandardDetailsScreen);

