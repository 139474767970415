
export const SECURITY_STANDARDS_BASE_URL = `/security-standards`;

export const buildGuidelineUrl = (securityStandardId, guidelineId) => {
  return `${SECURITY_STANDARDS_BASE_URL}/${securityStandardId}/guidelines/${guidelineId}`;
}


export const buildStandardUrl = (securityStandardId) => {
  return `${SECURITY_STANDARDS_BASE_URL}/${securityStandardId}`;
}

export const buildStandardSearchUrl = (searchText) => {
  return `${SECURITY_STANDARDS_BASE_URL}/search?q=${searchText}`;
}
