import {createStore, applyMiddleware, compose} from 'redux';

import createReducer from './reducers';
import promiseMiddleware from "./config/middlewares/promise";


// If Redux DevTools Extension is installed use it, otherwise use Redux compose
/* eslint-disable no-underscore-dangle */
const composeEnhancers =
  process.env.NODE_ENV !== 'production' &&
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // TODO Try to remove when `react-router-redux` is out of beta, LOCATION_CHANGE should not be fired more than once after hot reloading
      // Prevent recomputing reducers for `replaceReducer`
      shouldHotReload: false,
    })
    : compose;

/* eslint-enable */


const middlewares = [
  promiseMiddleware,
];

const store = createStore(
  createReducer(),
  composeEnhancers(applyMiddleware(...middlewares))
);

// Extensions
store.injectedReducers = {};
store.reloadReducers = () => store.replaceReducer(createReducer(store.injectedReducers));

export default store;

export const getStore = () => store;
