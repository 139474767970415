import React, {Component} from 'react';
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import I18n from "amn/ui/i18n/I18n";
import {ProviderContext as SnackbarProps, withSnackbar} from 'notistack';
import {I18N_NS_STANDARDS} from "amn/common/i18n/I18nextConfig";
import type {SecurityStandardGuideline, SecurityStandardUserNote} from "amn/model/standards/SecurityStandards";
import {SecurityStandardContext} from "../../SecurityStandardContexts";
import {createStandardUserNote} from "amn/store/actions/notes/security-standard-user-notes.actions";
import firebaseAuth from "amn/firebase/auth/FirebaseAuth";
import AuthChecker from "amn/store/actors/iam/AuthChecker";
import RichTextField from "amn/ui/forms/RichTextField";
import {nanoid} from "nanoid";
import {withTranslation} from "react-i18next";

type Props = {
  initialData?: SecurityStandardUserNote;
  onCancel?: ()=>void;
  onSuccess?: ()=>void;
  guideline: SecurityStandardGuideline;
} & SnackbarProps;

type State = {
  data: SecurityStandardUserNote;
};

class GuidelineNoteForm extends Component<Props, State> {

  static contextType = SecurityStandardContext;

  static defaultProps = {
    initialData: {}
  };

  constructor(props) {
    super(props);
    this.id = nanoid();
    this.state = {
      data: props.initialData
    }
  }

  onTextChange = (value) => {
    const {data} = this.state;
    this.setState({data: {...data, text: value}});
  }

  submit = (event) => {
    event.preventDefault();
    const {data} = this.state;
    const {enqueueSnackbar,onSuccess, t} = this.props;
    AuthChecker.doIfAuthed(() => {
      createStandardUserNote({
        ...data,
        userId: firebaseAuth.getCurrentUser().uid,
        visibility: {
          type: "PRIVATE"
        },
        guidelineId: this.props.guideline.id,
        standardId: this.context.securityStandard.id
      }).then((response) => {
        enqueueSnackbar(t('standardNotes.creationSuccess'), {variant: 'success'});
        this.reset();
        onSuccess && onSuccess();
      });
    })

  }

  reset = () => {
    this.id = nanoid();
    this.setState({data: {}});
  }


  render() {
    const {data} = this.state;
    return (
      <form key={this.id} onSubmit={this.submit} onReset={this.reset}>
        <Box mt={1}>

          <RichTextField onTextChange={this.onTextChange}
                         autoFocus
                         inlineToolbar
                         controls={[
                           "title", "bold", "italic", "underline", "strikethrough", "highlight", "undo", "redo", "link", "numberList", "bulletList", "quote", "code"
                         ]}
                         label="Start typing..."/>
        </Box>

        <Box mt={3}
             mb={1}
             display="flex"
             justifyContent="flex-end">
          <Box ml={1}>
            <Button onClick={this.props.onCancel}>
              <I18n>
                Cancel
              </I18n>
            </Button>
          </Box>
          <Box ml={1}>
            <Button type="submit"
                    variant="contained"
                    color="primary">
              <I18n ns={I18N_NS_STANDARDS} i18nKey={"standardNotes.create"}>
                Create
              </I18n>
            </Button>
          </Box>
        </Box>

      </form>
    );
  }

}


export default withSnackbar(withTranslation(I18N_NS_STANDARDS)(GuidelineNoteForm));



