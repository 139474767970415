import React, {Component} from 'react';
import Box from "@material-ui/core/Box";
import map from "lodash/map";
import includes from "lodash/includes";
import truncate from "lodash/truncate";
import remove from "lodash/remove";
import {selectSecurityStandardVariants} from "amn/store/selectors/standards/security-standards.selectors";
import type {SecurityStandard, SecurityStandardVariant} from "amn/model/standards/SecurityStandards";
import type {LookupTable} from "amn/model/common/Divers";
import {connect} from "react-redux";
import ButtonBase from "@material-ui/core/ButtonBase";
import toMaterialStyle from "material-color-hash";

type Props = {
  securityStandard: SecurityStandard;
  variants: LookupTable<SecurityStandardVariant>;
  value: string[];
  onChange: (value: SecurityStandardVariant)=>void;
};

type State = {};

class SecurityStandardVariantsSwitch extends Component<Props, State> {

  onToggle = (variant, isSelected) => {
    const {onChange, value} = this.props;
    const newValue = [...value]
    if (isSelected) {
      remove(newValue, (id) => id === variant.id)
    } else {
      newValue.push(variant.id)
    }

    onChange && onChange(newValue);
  }

  render() {
    const {variants, value} = this.props;
    return (
      <Box display="flex">
        {map(variants, (variant) => {
          const isSelected = includes(value, variant.id);
          const style = toMaterialStyle(variant.id);
          return (
            <Box mr={1} key={variant.id}>
              <ButtonBase
                onClick={() => this.onToggle(variant, isSelected)}>
                <Box key={variant}
                     fontWeight={"fontWeightBold"}
                     borderRadius="borderRadius"
                     color={isSelected ? style.backgroundColor : "text.disabled"}
                     borderColor={isSelected ? style.backgroundColor : "text.disabled"}
                     border={1}
                     pr={1}
                     pl={1}
                     fontSize={"caption.fontSize"}
                     p={0.5}>
                  {truncate(variant.name, {'length': 12})}
                </Box>
              </ButtonBase>
            </Box>
          );

        })
        }
      </Box>
    );
  }

  static mapStateToProps = (state, props) => {
    return {
      variants: selectSecurityStandardVariants(state)(props.securityStandard?.id)
    }
  };
}


export default connect(SecurityStandardVariantsSwitch.mapStateToProps)(SecurityStandardVariantsSwitch);



