import {createSelector} from 'reselect';
import get from "lodash/get";
import filter from "lodash/filter";
import type {LookupTable} from "amn/model/common/Divers";
import type {DataStatus} from "../../reducers/common";
import memoize from "lodash/memoize";
import type {SecurityStandardUserNote} from "amn/model/standards/SecurityStandards";

export const selectStandardsUserNotesState = (state) => get(state, 'user.standardNotes');

export const selectStandardsUserNotes = createSelector(
  selectStandardsUserNotesState,
  (state): LookupTable<SecurityStandardUserNote> => get(state, 'entities', {})
);

export const selectStandardsUserNotesDataStatus = createSelector(
  selectStandardsUserNotesState,
  (state): DataStatus => get(state, 'status')
);

export const selectGuidelineNotes = createSelector(
  selectStandardsUserNotes,
  (notes: LookupTable<SecurityStandardUserNote>) => memoize(
    (guidelineId: string): SecurityStandardUserNote => filter(notes, note => note.guidelineId === guidelineId)
  )
);
