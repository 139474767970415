import produce from "immer";

import reduce from "lodash/reduce";
import forEach from "lodash/forEach";
import uniq from "lodash/uniq";
import filter from "lodash/filter";
import includes from "lodash/includes";
import map from "lodash/map";
import type {ListDataState, Reducer} from "../common";
import {DATA_ERROR, DATA_INITIAL, DATA_LOADED, DATA_LOADING} from "../common";
import type {
  SecurityStandard,
  SecurityStandardGuideline,
  SecurityStandardGuidelineRelationships,
  SecurityStandardVariant
} from "amn/model/standards/SecurityStandards";
import type {LookupTable} from "amn/model/common/Divers";
import {listToLookupTable} from "amn/model/common/Divers";
import {getAvailableStandards} from "amn/backend/local/standards/SecurityStandardsData";
import {
  LOAD_STANDARD_GUIDELINES_ERROR,
  LOAD_STANDARD_GUIDELINES_REQUEST,
  LOAD_STANDARD_GUIDELINES_SUCCESS
} from "../../actions/standards/security-standards.actions";
import {getEntityId} from "amn/model/common/Entity";


type SecurityStandardsSubEntities = {
  guidelines: ListDataState<SecurityStandardGuideline>;
  variants: ListDataState<SecurityStandardVariant>;
  guidelinesRelationships: ListDataState<SecurityStandardGuidelineRelationships>;
};

export type SecurityStandardsState = ListDataState<SecurityStandard> &
  {
    subEntities: LookupTable<SecurityStandardsSubEntities>
  };

const availableStandards = getAvailableStandards();
const INITIAL_STATE: SecurityStandardsState = {
  entities: listToLookupTable(availableStandards),
  status: DATA_LOADED,
  subEntities: reduce(availableStandards, (result, standard) => {
    result[standard.id] = {
      variants: {
        entities: listToLookupTable(standard.variants),
        status: DATA_LOADED,
      },
      guidelines: {
        status: DATA_INITIAL,
      },
      guidelinesRelationships: {
        status: DATA_INITIAL,
      },
    }
    return result;
  }, {}),
}


const getDirectParents = function (guidelines, guidelineId) {
  return map(filter(guidelines, other => includes(other.subGuidelines, guidelineId)), getEntityId);
};

const getGrandChildren = (guidelines: LookupTable<SecurityStandardGuideline>, subGuidelines: string[] = []) => {
  const result = [];
  forEach(subGuidelines, guidelineId => {
    result.push(guidelineId);
    result.push(...getGrandChildren(guidelines, guidelines[guidelineId].subGuidelines))
  });

  return result;
}

const getAncestors = (guidelines: LookupTable<SecurityStandardGuideline>, parentGuidelines: string[] = []) => {
  const result = [];
  forEach(parentGuidelines, parentGuidelineId => {
    result.push(parentGuidelineId);
    result.push(...getAncestors(guidelines, getDirectParents(guidelines, parentGuidelineId)))
  });
  return uniq(result);
}


const securityStandards: Reducer = produce((draft: SecurityStandardsState, action) => {
  switch (action.type) {

    ///******************************
    // Fetch standard guidelines

    case LOAD_STANDARD_GUIDELINES_REQUEST: {
      draft.subEntities[action.securityStandardId] = draft.subEntities[action.securityStandardId] || {}
      draft.subEntities[action.securityStandardId].guidelines = {
        entities: {},
        status: DATA_LOADING
      };
      draft.subEntities[action.securityStandardId].guidelinesRelationships = {
        entities: {},
        status: DATA_LOADING
      };
      break;
    }

    case LOAD_STANDARD_GUIDELINES_SUCCESS: {
      const subEntitiesHolder: SecurityStandardsSubEntities = draft.subEntities[action.request.securityStandardId];
      if (subEntitiesHolder) {
        const guidelines = listToLookupTable(action.success.items);
        subEntitiesHolder.guidelines = {
          entities: guidelines,
          status: DATA_LOADED
        };
        const relationships = reduce(guidelines, (result: LookupTable<SecurityStandardGuidelineRelationships>, guideline: SecurityStandardGuideline) => {
          const directParents = getDirectParents(guidelines, guideline.id);
          result[guideline.id] = {
            guidelineId: guideline.id,
            ancestorsIds: getAncestors(guidelines, directParents),
            descendentsIds: getGrandChildren(guidelines, guideline.subGuidelines),
            directParentsIds: directParents,
            directChildrenIds: guideline.subGuidelines || [],
          }
          return result;
        }, {});
        subEntitiesHolder.guidelinesRelationships = {
          entities: relationships,
          status: DATA_LOADED
        };
      }
      break;
    }

    case LOAD_STANDARD_GUIDELINES_ERROR: {
      draft.subEntities[action.request.securityStandardId] = draft.subEntities[action.request.securityStandardId] || {}
      draft.subEntities[action.request.securityStandardId].guidelines = {
        entities: {},
        status: DATA_ERROR
      }
      draft.subEntities[action.request.securityStandardId].guidelinesRelationships = {
        entities: {},
        status: DATA_ERROR
      }
      break;
    }

    default:
      break;
  }
}, INITIAL_STATE);


export default securityStandards;
