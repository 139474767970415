import {Auth0Client} from '@auth0/auth0-spa-js';
import Config from "amn/Config";


const auth0 = new Auth0Client({
  domain: Config.AUTH0_DOMAIN,
  // audience: 'http://localhost:8080',
  client_id: Config.AUTH0_CLIENT_ID,
  response_type: 'token id_token',
  scope: 'openid profile'
});

export default auth0;





