import firebase from "amn/firebase/index"


class FirebaseAuth {

  getCurrentUser() {
    return firebase.auth().currentUser;
  }

  getCurrentUserId() {
    const currentUser = this.getCurrentUser();
    return currentUser?.uid;
  }

  async updateProfile(profile) {
    if (!firebase.auth().currentUser) return;
    await firebase.auth().currentUser.updateProfile({
      displayName: profile.name,
      photoURL: profile.picture,
    });
  }

  async signOut() {
    await firebase.auth().signOut();
  }

  setAuthStateListener(listener) {
    return firebase.auth().onAuthStateChanged(listener);
  }

  async signInWithCustomToken(token) {
    try {
      await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
      await firebase.auth().signInWithCustomToken(token);
    } catch (err) {
      console.log(err)
    }
  }
}


const firebaseAuth = new FirebaseAuth();

export default firebaseAuth;
