// @flow


import type {Reducer} from "../common";
import {CLOSE_LOGIN_DIALOG, OPEN_LOGIN_DIALOG} from "../../actions/iam/iam-ui.actions";
import produce from "immer";

export type UIState = {
  loginDialog: {
    open: boolean;
    reason?: string;
  }
}

const INITIAL_STATE: UIState = {
  loginDialog: {
    open: false
  }
};

const ui: Reducer = produce((draft: UIState, action) => {

  switch (action.type) {
    case OPEN_LOGIN_DIALOG: {
      draft.loginDialog = {
        open: true,
        reason: action.reason
      }
      break;
    }

    case CLOSE_LOGIN_DIALOG: {
      draft.loginDialog = {
        open: false
      }
      break;
    }

    default:
      break;
  }
}, INITIAL_STATE);


export default ui;

