import {loadStandardGuidelines} from "amn/backend/local/standards/SecurityStandardsData";
import type {ListResponse} from "amn/model/common/Responses";
import type {SecurityStandardGuideline} from "amn/model/standards/SecurityStandards";


const SecurityStandardsBackendAdapter = {

  loadSecurityStandardGuidelines: (id: string): Promise<ListResponse<SecurityStandardGuideline>> => {
    return loadStandardGuidelines(id)
      .then(guidelines => ({items: guidelines}))
  }
}

export default SecurityStandardsBackendAdapter;
