import React, {Component} from 'react';
import SecurityStandardDetails from "./SecurityStandardDetails";
import type {SecurityStandard} from "amn/model/standards/SecurityStandards";
import {loadStandardGuidelines} from "amn/store/actions/standards/security-standards.actions";
import LoadingContainer from "amn/ui/containers/LoadingContainer";

type Props = {
  securityStandard: SecurityStandard;
};

type State = {
  loading: boolean;
};


class SecurityStandardDetailsViewer extends Component<Props, State> {

  state = {
    loading: true,
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const {securityStandard} = this.props;
    loadStandardGuidelines(securityStandard.id)
      .then(() => {
        this.setState({loading: false})
      })
      .catch(() => {
        this.setState({loading: false})
      });
  }


  render() {

    const {securityStandard} = this.props;
    const {loading} = this.state;

    return (
      <LoadingContainer loading={loading}>

        <SecurityStandardDetails securityStandard={securityStandard}/>

      </LoadingContainer>

    );
  }
}


export default SecurityStandardDetailsViewer;

