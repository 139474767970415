import React from 'react';
import reduce from "lodash/reduce";
import withWidth, {isWidthUp} from '@material-ui/core/withWidth';

const breakpoints = ["xs",
  "sm",
  "md",
  "lg",
  "xl"
]
const WithBreakpoints = ({children, width}) => {
  const bps = reduce(breakpoints, (result, bp) => {
    result[bp + "Up"] = isWidthUp(bp, width);
    return result;
  }, {})
  return (
    <React.Fragment>
      {children(bps)}
    </React.Fragment>
  )
};

export default withWidth()(WithBreakpoints);
