import React, {Component, RefObject} from 'react';

import MUIRichTextEditor, {TMUIRichTextEditorProps, TMUIRichTextEditorRef} from 'mui-rte';
import type {RichText} from "./RichText";
import {RICH_TEXT_TYPE_DRAFT_JS} from "./RichText";
import {convertToRaw, EditorState} from 'draft-js'
import {withStyles} from "@material-ui/core/styles";


export type Props = {
  onTextChange?: (value: RichText)=> void;
  autoFocus?: boolean;
} & TMUIRichTextEditorProps;


class RichTextField extends Component<Props> {

  static defaultProps = {
    controls: ["title", "bold", "italic", "underline", "strikethrough", "highlight", "undo", "redo", "link", "numberList", "bulletList", "quote", "code"]
  }

  editorRef: RefObject<TMUIRichTextEditorRef>;

  constructor(props) {
    super(props);
    this.editorRef = React.createRef();
  }


  componentDidMount() {
    if (this.props.autoFocus && this.editorRef) {
      this.editorRef.current.focus()
    }
  }

  onChange = (editorState: EditorState) => {
    const {onChange, onTextChange} = this.props;
    if (onTextChange) {
      onTextChange({
        type: RICH_TEXT_TYPE_DRAFT_JS,
        content: JSON.stringify(convertToRaw(editorState.getCurrentContent()))
      })
    }
    onChange && onChange(editorState);
  }

  render() {

    const content = this.props.defaultValue ? this.props.defaultValue.content : null;
    return (
      <RichTextEditor {...this.props}
                      ref={this.editorRef}
                      onChange={this.onChange}
                      defaultValue={content}/>
    );
  }
}

const RichTextEditor = withStyles((theme) => ({
  container: {
    border: "1px solid " + "#f1f3f7",
    borderRadius: "3px"
  },
  editor: {
    padding: "8px",
  },
  toolbar: {
    borderBottom: "1px solid " + "#f1f3f7",
    background: "#f9f9f9"
  },
  placeHolder: {
    position: "initial",
    padding: "6px 8px 0 8px",
  }
}))(MUIRichTextEditor);


export default RichTextField;



