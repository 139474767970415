import React, {Component} from 'react';
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import {Helmet} from "react-helmet";
import SecurityStandardsViewer from "../components/standard/SecurityStandardsViewer";
import Container from "@material-ui/core/Container";
import I18n from "amn/ui/i18n/I18n";
import {I18N_NS_STANDARDS} from "amn/common/i18n/I18nextConfig";
import Alert from "@material-ui/lab/Alert";


class SecurityStandardsScreen extends Component {

  render() {
    return (
      <Container>
        <Helmet titleTemplate="Amn Standards | %s">
          <title lang="fr">Répertoire Gratuit de Référentiels de Cybersécurité</title>
          <meta name="description"
                content="Point d'accès unique pour consulter, rechercher, capitaliser et échanger autour des référentiels et guides cyber-sécurité. Naviguez de manière fluide dans les données des référentiels et créez votre propre espace personnel de capitalisation."/>
          <meta name="keywords"
                content={`Référentiel sécurité, Référentiel cybersécurité, ANSSI, NIST`}/>
        </Helmet>
        <Box mb={2}>
          <Typography variant="h5" gutterBottom>
            <I18n ns={I18N_NS_STANDARDS} i18nKey="standards.all">
              Security Standards
            </I18n>
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Alert severity="info">
              <I18n ns={I18N_NS_STANDARDS} i18nKey="standards.comingStandardsProgressAlertMessage">
                comingStandardsProgressAlertMessage
              </I18n> <a href="https://amnbrains.com/contact" target="_blank">amnbrains.com</a>.
            </Alert>
          </Grid>

          <Grid item xs={12} zeroMinWidth>
            <SecurityStandardsViewer/>
          </Grid>

        </Grid>
      </Container>
    );
  }
}


export default SecurityStandardsScreen;



